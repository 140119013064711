import Header from "../../components/header/Header";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search, CSVExport
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import Select from "react-dropdown-select";
import { useEffect, useState } from "react";
import axios, { CancelToken } from "../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import StatusToast from "../../components/StatusToast";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { useRef } from "react";
import generatePDF from "react-to-pdf";
import Loader from "../../loader/loader";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';



const DueReports = () => {
  const { SearchBar } = Search;
  const [data, setData] = useState([]);
  const { auth } = useAuth();
  const { ExportCSVButton } = CSVExport;
  const targetRef = useRef();
  const [centerList, setcenterList] = useState([]);
  const [centerName, setcenterName] = useState("");
  const [status, setStatus] = useState("0");

  const [employeeList, setemployeeList] = useState([]);
  const [employeeName, setemployeeName] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [state, setState] = useState({
    start: moment().subtract(29, 'days'),
    end: moment(),
  });
  const { start, end } = state;
  const handleCallback = (start, end) => {
    setState({ start, end });
  };
  const label =
    start.format('D MMM, YYYY') + ' - ' + end.format('D MMM, YYYY');
  useEffect(() => {
    const centerList = async () => {
      try {
        let response = await axios.get("/store/list", {
          headers: { authorization: "bearer " + auth.token },
        });

        if (response) {
          setcenterList(response?.data?.list);

        } else {
          swal({
            title: "Error!",
            text: `${response?.data?.message}`,
            icon: "error",
          });
        }
      } catch (err) {
        swal({ title: "Error!", text: `${err.message}`, icon: "error" });
        console.log(err.message);
      }
    };

    const employeeList = async () => {
      try {
        let response = await axios.get("/users/list", {
          headers: { authorization: "bearer " + auth.token },
        });

        if (response) {
          setemployeeList(response?.data?.list);
        } else {
          swal({
            title: "Error!",
            text: `${response?.data?.message}`,
            icon: "error",
          });
        }
      } catch (err) {
        swal({ title: "Error!", text: `${err.message}`, icon: "error" });
        console.log(err.message);
      }
    };
    employeeList();
    centerList();
  }, []);

  const smartSearch = async (cancelToken) => {
    setisLoading(true);
    setStatus("1");

    try {
      const response = await axios.get(
        "/due/emi-list",
        {
          headers: { authorization: "bearer " + auth.token },
          cancelToken: cancelToken.token,
          params: {staffId:employeeName,startdate:start,enddate:end}
        }
      );
      console.log(response.data);
      if (response?.data?.success) {
        setData(response.data.list);
      }
      setisLoading(false);
    } catch (err) {
      setisLoading(false);
      console.error(err);
    }
  };
  

  const loadData = async (cancelToken) => {
    try {
      const response = await axios.get("/due/emi-list", {
        headers: { authorization: "bearer " + auth.token },
        cancelToken: cancelToken.token,
        params: {startdate:start,enddate:end}
      });
      console.log(response?.data);
      if (response?.data?.success) {
        setData(response.data.list);
      }
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    const cancelToken = CancelToken.source();
    loadData(cancelToken);
  }, []);

  function calculateDaysFromGivenDate(dateString) {
    if (!dateString) {
      return "";
    }
    // Parse the given date string to a Date object
    const givenDate = new Date(dateString);

    // Get the current date
    const currentDate = new Date();

    // Calculate the difference in milliseconds
    const differenceInMilliseconds = currentDate - givenDate;

    // Calculate the difference in days
    const differenceInDays = Math.floor(
      differenceInMilliseconds / (1000 * 60 * 60 * 24)
    );

    return differenceInDays;
  }

  const storeManager = [
    {
      dataField: "centerId",
      text: "Group ID",
    },
    {
      dataField: "staffName",
      isDummyField: true,
      text: "Staff Name",
      formatter: (c, row, i, extra) => {
        console.log(row?.joinedData);
        return row?.joinedData[0]?.staffName;
      },
    },
    {
      dataField: "Member Name",
      isDummyField: true,
      text: "Member Name",
      formatter: (c, row, i, extra) => {
        console.log(row?.joinedData);
        return row?.joinedData[0]?.customer;
      },
    },
    // {
    //   dataField: "parent",
    //   isDummyField: true,
    //   text: "Parent Name",
    //   formatter: (c, row, i, extra) => {
    //     console.log(row?.joinedData);
    //     return row?.joinedData[0]?.parent;
    //   },
    // },
    {
      dataField: "mobile",
      isDummyField: true,
      text: "Mobile",
      formatter: (c, row, i, extra) => {
        console.log(row?.joinedData);
        return row?.joinedData[0]?.mobile;
      },
    },
    {
      dataField: "emiAmount",
      text: "EMI",
    },
    {
      dataField: "emiNumber",
      text: "EMI No.",
    },
    {
      dataField: "Over Due Days",
      isDummyField: true,
      text: "Over Due Days",
      formatter: (c, row, i, extra) => {
        return calculateDaysFromGivenDate(row?.emiPayDate);
      },
    },
  ];

  const employee = [
    {
      dataField: "centerId",
      text: "Group ID",
    },
    {
      dataField: "staffName",
      isDummyField: true,
      text: "Staff Name",
      formatter: (c, row, i, extra) => {
        console.log(row?.joinedData);
        return row?.joinedData[0]?.staffName;
      },
    },
    {
      dataField: "Member Name",
      isDummyField: true,
      text: "Member Name",
      formatter: (c, row, i, extra) => {
        console.log(row?.joinedData);
        return row?.joinedData[0]?.customer;
      },
    },
    // {
    //   dataField: "parent",
    //   isDummyField: true,
    //   text: "Parent Name",
    //   formatter: (c, row, i, extra) => {
    //     console.log(row?.joinedData);
    //     return row?.joinedData[0]?.parent;
    //   },
    // },
    {
      dataField: "mobile",
      isDummyField: true,
      text: "Mobile",
      formatter: (c, row, i, extra) => {
        console.log(row?.joinedData);
        return row?.joinedData[0]?.mobile;
      },
    },
    {
      dataField: "emiAmount",
      text: "EMI",
    },
    {
      dataField: "emiNumber",
      text: "EMI No.",
    },
    {
      dataField: "Over Due Days",
      isDummyField: true,
      text: "Over Due Days",
      formatter: (c, row, i, extra) => {
        return calculateDaysFromGivenDate(row?.emiPayDate);
      },
    },
  ];

  const admin = [
    {
      dataField: "storeName",
      isDummyField: true,
      text: "Branch Name",
      formatter: (c, row, i, extra) => {
        console.log(row?.storeData);
        return row?.storeData[0]?.storeName;
      },
    },
    {
      dataField: "centerId",
      text: "Group ID",
    },
    {
      dataField: "staffName",
      isDummyField: true,
      text: "Staff Name",
      formatter: (c, row, i, extra) => {
        console.log(row?.joinedData);
        return row?.joinedData[0]?.staffName;
      },
    },
    {
      dataField: "Member Name",
      isDummyField: true,
      text: "Member Name",
      formatter: (c, row, i, extra) => {
        console.log(row?.joinedData);
        return row?.joinedData[0]?.customer;
      },
    },
    // {
    //   dataField: "parent",
    //   isDummyField: true,
    //   text: "Parent Name",
    //   formatter: (c, row, i, extra) => {
    //     console.log(row?.joinedData);
    //     return row?.joinedData[0]?.parent;
    //   },
    // },
    {
      dataField: "mobile",
      isDummyField: true,
      text: "Mobile",
      formatter: (c, row, i, extra) => {
        console.log(row?.joinedData);
        return row?.joinedData[0]?.mobile;
      },
    },
    {
      dataField: "emiAmount",
      text: "EMI",
    },
    {
      dataField: "emiNumber",
      text: "EMI No.",
    },
    {
      dataField: "Over Due Days",
      isDummyField: true,
      text: "Over Due Days",
      formatter: (c, row, i, extra) => {
        return calculateDaysFromGivenDate(row?.emiPayDate);
      },
    },
  ];

  let column =
    auth?.user?.role == "admin"
      ? admin
      : auth?.user?.role == "storeManager"
      ? storeManager
      : employee;

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: data?.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };
  return (
    <>
      <Header page="Due EMI List" />
      <section className="content">
        <div className="row">
          <div className="col">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Due EMI List</h3>
              </div>
              
              <div className="card-body">
              
              


                <ToolkitProvider
                  keyField="id"
                  data={data}
                  columns={column}
                  search
                  exportCSV={{
                    fileName: "PaidEmiList.csv",
                  }}
                >
                  {(props) => (
                    <div>
                      <div className="row mt-3">
                      <div className="col-md-12 d-flex" id="search_box" >
                        <span
                          style={{
                            marginRight: "5px",
                            display: "flex",
                          }}
                        >
                          <Button
                            style={{ width: "60px", height: "40px" }}
                            variant="outline-primary"
                            onClick={(e) => {
                              setStatus("0");
                              let cancelToken = CancelToken.source();
                              loadData(cancelToken);
                            }}
                            active={status === "0" ? true : false}
                          >
                            All
                          </Button>{" "}
                        </span>
                          {/* <h5>Input something at below input field:</h5> */}
                         <SearchBar {...props.searchProps} />
                        <span style={{ marginRight: "5px", marginLeft: "5px"}}>
                          <ExportCSVButton {...props.csvProps}> Export Excel</ExportCSVButton>
                        </span>
                          <button className="bg-light rounded mb-2" onClick={() => generatePDF(targetRef, {filename: 'paidEmilist.pdf'})}>Download PDF</button>
                          <DateRangePicker
        initialSettings={{
          startDate: start.toDate(),
          endDate: end.toDate(),
          ranges: {
            Today: [moment().toDate(), moment().toDate()],
            Yesterday: [
              moment().subtract(1, 'days').toDate(),
              moment().subtract(1, 'days').toDate(),
            ],
            'Last 7 Days': [
              moment().subtract(6, 'days').toDate(),
              moment().toDate(),
            ],
            'Last 30 Days': [
              moment().subtract(29, 'days').toDate(),
              moment().toDate(),
            ],
            'This Month': [
              moment().startOf('month').toDate(),
              moment().endOf('month').toDate(),
            ],
            'Last Month': [
              moment().subtract(1, 'month').startOf('month').toDate(),
              moment().subtract(1, 'month').endOf('month').toDate(),
            ],
            'This Year': [
              moment().startOf('year').toDate(),
              moment().endOf('year').toDate(),
            ],
          },
        }}
        onCallback={handleCallback}
      >
        <div
          id="reportrange"
          style={{
            background: '#fff',
            cursor: 'pointer',
            border: '1px solid #ccc',
            marginLeft:"5px",
            width:"20%",
            height:"40px"
          }}
        >
          &nbsp;<i className="fa fa-calendar"></i>&nbsp;
          <span>{label}</span> 
        </div>
      </DateRangePicker>
                    <div className="col-md-2">

                    <Select
                      options={employeeList}
                      searchBy="username"
                      labelField="username"
                      valueField="username"
                      placeholder="Select Employee..."
                      multi={false}
                      loading={false}
                      handle={true}
                      color="#007bff"
                      clearable={true}
                      keepSelectedInList={true}
                      closeOnSelect={true}
                      dropdownPosition="bottom"
                      direction="ltr"
                      dropdownHeight="1000px"
                      onChange={(values) => {
                        setemployeeName(values[0]?.staffId);
                      }}
                    />
                    </div>

{!isLoading ? (
    <input
      style={{
        backgroundColor: "#00a65a",
        color: "white",
        width:"8%",
        height: "38px",
        border: "none",
      }}
      type="button"
      value="Search"
      name="add_Search"
      onClick={smartSearch}
    />
  ) : (
    <Loader isLoading={isLoading} />
  )}



                      </div>
                
                       </div>
                      
                      <div ref={targetRef}>
                      <BootstrapTable
                        wrapperClasses="table-responsive"
                        noDataIndication="Table is Empty"
                        striped
                        {...props.baseProps}
                        pagination={paginationFactory(options)}
                        filter={filterFactory()}
                      />
                      </div>
                      <hr />
                      {/* <BootstrapTable
                        wrapperClasses="table-responsive"
                        noDataIndication="Table is Empty"
                        striped
                        {...props.baseProps}
                        pagination={paginationFactory(options)}
                        filter={filterFactory()}
                      /> */}
                    </div>
                  )}
                </ToolkitProvider>
              </div>
            </div>
          </div>
        </div>

        <StatusToast />
      </section>
    </>
  );
};
export default DueReports;
