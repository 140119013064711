import Home from "../pages/Home";
import Login from "../pages/Login";
import UserList from "../pages/UserList";
import AddUser from "../pages/AddUser";
import AddStore from "../pages/AddStore";
import StoreList from "../pages/StoreList";
import AddProduct from "../pages/AddProduct";
import ProductList from "../pages/ProductList";
import AddCustomer from "../pages/AddCustomer";
import CustomerList from "../pages/CustomerList";
import createInvoice from "../pages/createInvoice";
import invoiceList from "../pages/invoiceList";
import ViewInvoice from "../pages/ViewInvoice";
import AddCategory from "../pages/AddCategory";
import CategoryList from "../pages/CategoryList";
import OnBoardForm from "../pages/OnBoardForm";
import OnBoardList from "../pages/OnBoardList";
import OnBoardFormView from "../pages/OnBoardFormView";
import CollectionSheet from "../pages/CollectionSheet";
import CollectionSheetList from "../pages/CollectionSheetList";
import CollectionSheetView from "../pages/CollectionSheetView";
import FinalReportCard from "../pages/FinalReportCard";
import FinalReportCardList from "../pages/FinalReportCardList";
import FinalReportCardView from "../pages/FinalReportCardView";
import AddCenter from "../pages/AddCenter";
import CenterList from "../pages/CenterList";
import OnBoardInvoicePrint from "../pages/OnBoardInvoicePrint";
import AddDueEMI from "../pages/AddDueEMI";
import DueEMIList from "../pages/DueEMIList";
import PaidEmiList from "../pages/PaidEmiList";
import TotalCollection from "../pages/reports/TotalCollection";
import DueReports from "../pages/reports/DueReports";
import StockReports from "../pages/reports/StockReports";
import TotalDisbursements from "../pages/reports/TotalDisbursements";
import AddDueDownpayment from "../pages/AddDueDownpayment";
import DueDownpaymentList from "../pages/DueDownpaymentList";


const path = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "user/list",
    component: UserList,
    roles: ["admin", "storeManager"],
  },
  {
    path: "/user/add",
    component: AddUser,
    roles: ["admin", "storeManager"],
  },
  {
    path: "store/list",
    component: StoreList,
    roles: ["admin"],
  },
  {
    path: "/store/add",
    component: AddStore,
    roles: ["admin"],
  },
  {
    path: "/product/add",
    component: AddProduct,
    roles: ["admin", "storeManager"],
  },
  {
    path: "/product/list",
    component: ProductList,
    roles: ["admin", "storeManager"],
  },
  {
    path: "/customer/add",
    component: AddCustomer,
    roles: ["admin", "storeManager"],
  },
  {
    path: "/customer/list",
    component: CustomerList,
    roles: ["admin", "storeManager"],
  },
  {
    path: "/invoice/add",
    component: createInvoice,
    roles: ["admin", "storeManager"],
  },
  {
    path: "/invoice/list",
    component: invoiceList,
    roles: ["admin", "storeManager"],
  },
  {
    path: "/view/invoice",
    component: ViewInvoice,
    roles: ["admin", "storeManager"],
  },
  {
    path: "/category/add",
    component: AddCategory,
    roles: ["admin", "storeManager"],
  },
  {
    path: "/category/list",
    component: CategoryList,
    roles: ["admin", "storeManager"],
  },
  {
    path: "/onboard/form/add",
    component: OnBoardForm,
    roles: ["admin", "storeManager", "employee"],
  },
  {
    path: "/onboard/form/list",
    component: OnBoardList,
    roles: ["admin", "storeManager", "employee"],
  },
  {
    path: "/onboard/form/view",
    component: OnBoardFormView,
    roles: ["admin", "storeManager", "employee"],
  },
  {
    path: "/collection/sheet/add",
    component: CollectionSheet,
    roles: ["admin", "storeManager", "employee"],
  },
  {
    path: "/collection/sheet/list",
    component: CollectionSheetList,
    roles: ["admin", "storeManager", "employee"],
  },
  {
    path: "/collection/sheet/view",
    component: CollectionSheetView,
    roles: ["admin", "storeManager", "employee"],
  },
  {
    path: "/final/reportCard/add",
    component: FinalReportCard,
    roles: ["admin", "storeManager"],
  },
  {
    path: "/final/reportCard/list",
    component: FinalReportCardList,
    roles: ["admin", "storeManager"],
  },
  {
    path: "/final/reportCard/view",
    component: FinalReportCardView,
    roles: ["admin", "storeManager"],
  },
  {
    path: "/center/add",
    component: AddCenter,
    roles: ["admin", "storeManager", "employee"],
  },
  {
    path: "/center/list",
    component: CenterList,
    roles: ["admin", "storeManager", "employee"],
  },
  {
    path: "/onboard/form/invoice/view",
    component: OnBoardInvoicePrint,
    roles: ["admin", "storeManager", "employee"],
  },
  {
    path: "/due/add/emi",
    component: AddDueEMI,
    roles: ["admin", "storeManager"],
  },
  {
    path: "/due/list/emi",
    component: DueEMIList,
    roles: ["admin", "storeManager", "employee"],
  },
  ,
  {
    path: "/due/add/downpayment",
    component: AddDueDownpayment,
    roles: ["admin", "storeManager"],
  },
  {
    path: "/due/list/downpayment",
    component: DueDownpaymentList,
    roles: ["admin", "storeManager", "employee"],
  },
  {
    path: "/paid/emi/list",
    component: PaidEmiList,
    roles: ["admin", "storeManager", "employee"],
  },
  {
    path: "/reports/totalcollection",
    component: TotalCollection,
    roles: ["admin", "storeManager", "employee"],
  },
  {
    path: "/reports/duereports",
    component: DueReports,
    roles: ["admin", "storeManager", "employee"],
  },
  {
    path: "/reports/stockreports",
    component: StockReports,
    roles: ["admin", "storeManager", "employee"],
  },
  {
    path: "/reports/totaldisbursements",
    component: TotalDisbursements,
    roles: ["admin", "storeManager", "employee"],
  },
  {
    path: "/login",
    component: Login,
    public: true,
  },
];

export default path;
