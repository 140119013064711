import { useRef, useState } from "react";
import Header from "../components/header/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import StatusToast from "../components/StatusToast";
import axios, { CancelToken } from "../api/axios";
import useAuth from "../hooks/useAuth";
import { useEffect } from "react";
import Select from "react-dropdown-select";
import { DatePicker } from "reactstrap-date-picker";
import "react-datepicker/dist/react-datepicker.css";

const FinalReportCard = () => {
  const location = useLocation();
  const data = location.state;
  // sadasy ka vivaran and housing sarveshan form
  const [customerName, setcustomerName] = useState(data?.customerName || "");
  const [parentName, setparentName] = useState(data?.parentName || "");
  const [customerAge, setcustomerAge] = useState(data?.customerAge || "");
  const [sadasyaGrahanDate, setsadasyaGrahanDate] = useState(
    data?.sadasyaGrahanDate || ""
  );
  const [center, setcenter] = useState(data?.center || "");
  const [centerId, setcenterId] = useState(data?.centerId || "");
  const [date, setdate] = useState(new Date());
  const [totalInstallment, settotalInstallment] = useState(
    data?.totalInstallment || 0
  );
  const [memberList, setmemberList] = useState([]);

  const navigate = useNavigate();
  const [isSubmit, setisSubmit] = useState(false);
  const { auth } = useAuth();

  // radio button

  const handleRadioChange = (event) => {
    setselectedGharMode(event.target.value);
  };

  const [customerList, setCustomerList] = useState([]);
  const [productList, setproductList] = useState([]);

  useEffect(() => {
    const customerListt = async () => {
      try {
        const response = await axios.get("/customer/list", {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        });
        setCustomerList(response.data.list);
        //console.log(response.data.list);
      } catch (err) {
        console.log(err);
      }
    };
    const itemLists = async () => {
      try {
        const response = await axios.get("/product/list", {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        });
        setproductList(response.data.list);
        //console.log(response.data.list);
      } catch (err) {
        console.log(err);
      }
    };
    const memberList = async () => {
      try {
        let response = await axios.get("/onboard-form/list", {
          headers: { authorization: "bearer " + auth.token },
        });

        if (response) {
          setmemberList(response?.data?.list);
          // console.log(response?.data?.list);
        } else {
          swal({
            title: "Error!",
            text: `${response?.data?.message}`,
            icon: "error",
          });
        }
      } catch (err) {
        swal({ title: "Error!", text: `${err.message}`, icon: "error" });
        console.log(err.message);
      }
    };
    memberList();
    customerListt();
    itemLists();
  }, []);

  // To insert data in table due to edit

  useEffect(() => {
    try {
      if (data) {
        let itemList = data?.data;
        // console.log(data);
        itemList?.map((item, index) => {
          const updatedField = {
            sr_no: 1,
            customerName: item?.customerName,
            productName: item?.productName,
            productAmount: item?.productAmount,
            depositAmount: item?.depositAmount,
            installment: item?.installment,
            totalInstallment: item?.totalInstallment,
            balance: item?.balance,
          };

          setFields((prevFields) => {
            const updatedFields = [...prevFields];
            updatedFields[index] = updatedField;
            return updatedFields;
          });
        });
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  const [fields, setFields] = useState([
    {
      sr_no: 1,
      productName: "",
      productAmount: 0,
      depositAmount: 0,
      dueAmount: 0,
      installment: 0,
      totalInstallment: 0,
    },
  ]);

  const addInputField = () => {
    const sr_no = fields.length + 1;
    const newField = {
      sr_no,
      productName: "",
      productAmount: 0,
      depositAmount: 0,
      dueAmount: 0,
      installment: 0,
      totalInstallment: 0,
    };
    setFields((prevFields) => [...prevFields, newField]);
  };

  const removeInputField = (index) => {
    setFields((prevFields) => prevFields.filter((_, i) => i !== index));
  };

  const updateFieldValue = (index, field, value) => {
    setFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][field] = value;
      return updatedFields;
    });
  };
  // console.log(fields);
  const handleClose = async () => {
    navigate("/");
  };

  const Submit = async () => {
    if (
      !center ||
      !centerId ||
      !customerName ||
      !customerAge ||
      !parentName ||
      !sadasyaGrahanDate ||
      !date
    ) {
      return swal({
        title: "चेतावनी!",
        text: `
        कृपया सदस्य & सेंटर का सारा विवरण भरें`,
        icon: "success",
      });
    }
    // console.log(fields);
    var count = 0;
    fields.map((item) => {
      const {
        productName,
        productAmount,
        depositAmount,
        installment,
        totalInstallment,
      } = item;
      if (!productName || !productAmount || !depositAmount || !installment) {
        count++;
        return swal({
          title: "चेतावनी!",
          text: `कृपया  प्रोडक्ट का सारा विवरण भरें`,
          icon: "warning",
        });
      }
    });

    if (isSubmit) return;
    setisSubmit(true);

    try {
      const id = data?._id;
      const url = data?._id
        ? "/final-report-card/" + id
        : "/final-report-card/add";
      const response = await axios.post(
        url,
        {
          customerName,
          customerAge,
          parentName,
          center,
          date,
          sadasyaGrahanDate,
          centerId,
          totalInstallment,
          data: fields,
        },
        {
          headers: { authorization: "bearer " + auth.token },
        }
      );
      // console.log(response);
      if (response?.data?.success) {
        swal({
          title: "Success!",
          text: `${response.data.message}`,
          icon: "success",
        });

        navigate("/final/reportCard/list");
      } else {
        swal({
          title: "Failure!",
          text: `${response.data.message}`,
          icon: "error",
        });
      }
    } catch (err) {
      swal({ title: "Error!", text: `${err.message}`, icon: "error" });
    } finally {
      setisSubmit(false);
    }
  };

  return (
    <>
      <Header page="Final Report Card" />
      <section className="content">
        <div className="row">
          <div className="col">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">रिपोर्ट कार्ड</h3>
              </div>
              <form className="needs-validation" noValidate>
                <div className="card-body">
                  <div className="form-group">
                    <h3
                      className="card-header"
                      style={{
                        fontFamily: "monospace",
                        fontWeight: "bolder",
                      }}
                    >
                      सदस्य & सेंटर का विवरण
                    </h3>
                    <br />
                    <div className="row" id="2">
                      <div className="col-md-3">
                        <label htmlFor="name">सदस्य का नाम :</label>
                        <Select
                          options={memberList}
                          searchBy="customer"
                          labelField="customer"
                          valueField="customer"
                          multi={false}
                          loading={false}
                          handle={true}
                          color="#007bff"
                          clearable={true}
                          keepSelectedInList={true}
                          closeOnSelect={true}
                          dropdownPosition="bottom"
                          direction="ltr"
                          dropdownHeight="1000px"
                          onChange={(values) => {
                            const selectedCustomerdata = values[0]?.data[0];
                            const selectedCustomer = values[0];
                            if (selectedCustomerdata) {
                              const updatedFields = fields.map(
                                (field, index) => {
                                  if (index === 0) {
                                    return {
                                      ...field,
                                      productName:
                                        selectedCustomerdata.productName || "",
                                      productAmount:
                                        selectedCustomerdata.productAmount ||
                                        "",
                                      depositAmount:
                                        selectedCustomer.downPayment || "",
                                      totalInstallment:
                                        selectedCustomer.totalMonthluNumber ||
                                        "",
                                    };
                                  }
                                  return field;
                                }
                              );
                              setFields(updatedFields);
                            }
                            setcustomerName(values[0]?.customer);
                            setparentName(values[0]?.parent);
                            setcenter(values[0]?.center);
                            setcenterId(values[0]?.centerId);
                            setcustomerAge(values[0]?.age);
                            setsadasyaGrahanDate(values[0]?.date);
                          }}
                        />
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="name">पिता/पति/पुत्र का नाम :</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          readOnly
                          id="name"
                          placeholder="पिता/पति/पुत्र का नाम"
                          value={parentName}
                          onChange={(e) => setparentName(e.target.value)}
                        />
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="name">सेंटर का नाम :</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          readOnly
                          id="name"
                          placeholder="सेंटर का नाम"
                          value={center}
                          onChange={(e) => setcenter(e.target.value)}
                        />
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="name">वर्तमान दिनांक :</label>
                        <DatePicker
                          dateFormat="DD/MM/YYYY"
                          disabled
                          showClearButton={false}
                          id="to-date"
                          value={date}
                          onChange={(e) => setdate(e)}
                          placeholder="दिनांक"
                          maxDate={new Date().toISOString()}
                        />
                      </div>
                    </div>
                    {/* <br /> */}
                    <div className="col-md-6 row">
                      <label htmlFor="whom">सेंटर आईडी :</label>
                      <input
                        required
                        type="text"
                        className="form-control"
                        readOnly
                        id="सेंटर"
                        placeholder="सेंटर आईडी"
                        value={centerId}
                        onChange={(e) => setcenterId(e.target.value)}
                      />
                    </div>
                    {/* <br /> */}
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="whom">सदस्य की उम्र :</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          readOnly
                          id="सदस्य की उम्र"
                          placeholder="सदस्य की उम्र"
                          value={customerAge}
                          onChange={(e) => setcustomerAge(e.target.value)}
                        />
                      </div>

                      <div className="col-md-3">
                        <label htmlFor="name">
                          सदस्यता ग्रहण करने की तारिख :
                        </label>
                        <DatePicker
                          dateFormat="DD/MM/YYYY"
                          showClearButton={false}
                          disabled
                          id="to-date"
                          value={sadasyaGrahanDate}
                          onChange={(e) => setsadasyaGrahanDate(e)}
                          placeholder="दिनांक"
                          maxDate={new Date().toISOString()}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <h3
                      className="card-header"
                      style={{
                        fontFamily: "monospace",
                        fontWeight: "bolder",
                      }}
                    >
                      प्रोडक्ट का विवरण
                    </h3>
                    <br />
                    <table
                      id="invoice-item-table"
                      className="table table-bordered"
                    >
                      <tbody>
                        <tr>
                          <th width="1%">क्रमांक संख्या.</th>
                          <th width="25%">प्रोडक्ट का नाम</th>
                          <th width="15%">प्रोडक्ट का मूल्य</th>
                          <th width="15%">जमा राशि</th>
                          <th width="15%">अदायगी रकम</th>
                          <th width="10%">कुल किस्त</th>
                          <th width="15%">मासिक किस्त</th>
                        </tr>
                        {fields.map((field, index) => (
                          <tr key={index}>
                            <td>
                              <span id="sr_no">{field.sr_no}</span>
                            </td>
                            <td>
                              <input
                                type="text"
                                name="productName[]"
                                id={`productName${field.sr_no}`}
                                data-srno={field.sr_no}
                                className="form-control input-sm number_only productName"
                                value={field.productName}
                                readOnly
                                onChange={(e) =>
                                  updateFieldValue(
                                    index,
                                    "productName",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="productAmount[]"
                                id={`productAmount${field.sr_no}`}
                                data-srno={field.sr_no}
                                className="form-control input-sm number_only productAmount"
                                value={field.productAmount}
                                readOnly
                                onChange={(e) =>
                                  updateFieldValue(
                                    index,
                                    "productAmount",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="depositAmount[]"
                                id={`depositAmount${field.sr_no}`}
                                data-srno={field.sr_no}
                                className="form-control input-sm depositAmount"
                                readOnly
                                value={field.depositAmount}
                                onChange={(e) =>
                                  updateFieldValue(
                                    index,
                                    "depositAmount",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="dueAmount[]"
                                id={`dueAmount${field.sr_no}`}
                                data-srno={field.sr_no}
                                className="form-control input-sm totalInstallment"
                                readOnly
                                value={Math.round(
                                  +field.productAmount - +field.depositAmount
                                )}
                                onChange={(e) =>
                                  updateFieldValue(
                                    index,
                                    "dueAmount",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="installment[]"
                                id={`installment${field.sr_no}`}
                                data-srno={field.sr_no}
                                className="form-control input-sm number_only installment"
                                readOnly
                                value={field.totalInstallment}
                                onChange={(e) =>
                                  updateFieldValue(
                                    index,
                                    "totalInstallment",
                                    e.target.value
                                  )
                                }
                              />
                            </td>

                            <td>
                              <input
                                type="text"
                                name="balance[]"
                                id={`balance${field.sr_no}`}
                                data-srno={field.sr_no}
                                className="form-control input-sm balance"
                                readOnly
                                value={
                                  Math.round(
                                    (+field.productAmount -
                                      +field.depositAmount) /
                                      +field.totalInstallment
                                  ) || 0
                                }
                                onChange={(e) =>
                                  updateFieldValue(
                                    index,
                                    "installment",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            {fields[index]?.sr_no == 1 ? (
                              <td>
                                <button
                                  type="button"
                                  name="add_row"
                                  id="add_row"
                                  className="btn btn-success btn-xs"
                                  onClick={addInputField}
                                >
                                  +
                                </button>
                              </td>
                            ) : (
                              ""
                            )}
                            {fields[index]?.sr_no != 1 ? (
                              <td>
                                <button
                                  type="button"
                                  name="add_row"
                                  id="add_row"
                                  className="btn btn-danger btn-xs remove_row"
                                  onClick={() => removeInputField(index)}
                                >
                                  X
                                </button>
                              </td>
                            ) : (
                              ""
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <br />
                  </div>
                </div>
                <center>
                  <div className="card-footer">
                    <input
                      style={{
                        backgroundColor: "#00a65a",
                        color: "white",
                        width: "15%",
                        height: "38px",
                        border: "none",
                        marginRight: "40px",
                      }}
                      type="button"
                      value="Save"
                      name="add_save"
                      disabled={isSubmit}
                      onClick={Submit}
                    />
                    <input
                      style={{
                        backgroundColor: "#f39c12",
                        color: "white",
                        width: "15%",
                        height: "38px",
                        border: "none",
                        marginRight: "40px",
                      }}
                      type="button"
                      value="Close"
                      name="add_save"
                      onClick={handleClose}
                    />
                  </div>
                  <StatusToast />
                </center>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default FinalReportCard;
