import Header from "../components/header/Header";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search, CSVExport
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";

import { useEffect, useState } from "react";
import axios, { CancelToken } from "../api/axios";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import StatusToast from "../components/StatusToast";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { useRef } from 'react';
import generatePDF from 'react-to-pdf';

const ActionDropdown = ({ data, loadData }) => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const handleDelete = async () => {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
    if (!willDelete) return;
    try {
      let id = data?._id;
      let response = axios.delete("/store/" + id, {
        headers: { authorization: "Bearer " + auth.token },
      });
      response = await toast.promise(response, {
        pending: "Deleting Entry",
        success: "Entry Deleted Successfully!",
        error: "Something went Wrong!",
      });
      console.log(response.data);

      const cancelToken = CancelToken.source();
      loadData(cancelToken);
    } catch (err) {
      console.log(err);
    }
  };
  const handleEdit = () => {
    console.log(data);
    navigate("/store/add", { state: data });
  };

  const payEMI = async () => {
    const willPay = await swal({
      title: "Are you sure?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
    if (!willPay) return;

    try {
      let id = data?._id;
      let response = axios.get("/due-downpayment/pay/" + id, {
        headers: { authorization: "Bearer " + auth.token },
      });
      response = await toast.promise(response, {
        pending: "Paying....",
        success: "EMI Pay Successfully!",
        error: "Something went Wrong!",
      });
      console.log(response.data);

      const cancelToken = CancelToken.source();
      loadData(cancelToken);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <DropdownButton id="dropdown-basic-button" title="Action">
        {/* <Dropdown.Item onClick={handleEdit}>Edit</Dropdown.Item> */}
        {/* <Dropdown.Item onClick={handleDelete}>Delete</Dropdown.Item> */}
        <Dropdown.Item onClick={payEMI}>Pay Down Payment</Dropdown.Item>
      </DropdownButton>
    </>
  );
};

const DueList = () => {
  const { SearchBar } = Search;
  const [data, setData] = useState([]);
  const { auth } = useAuth();
  const { ExportCSVButton } = CSVExport;
  const targetRef = useRef();

  
  const loadData = async (cancelToken) => {
    try {
      const response = await axios.get("/due-downpayment/downpayment-list", {
        headers: { authorization: "bearer " + auth.token },
        cancelToken: cancelToken.token,
      });

      if (response?.data?.success) {
        const emiList = response.data.list;
        setData(emiList);
      }
    //   setData(emiList);
    //   else {
    //     console.log(`emiType is null for object with _id ${item._id}`);
    // }
    // });
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    const cancelToken = CancelToken.source();
    loadData(cancelToken);
  }, []);

  function calculateDaysFromGivenDate(dateString) {
    if (!dateString) {
      return "";
    }
    // Parse the given date string to a Date object
    const givenDate = new Date(dateString);

    // Get the current date
    const currentDate = new Date();

    // Calculate the difference in milliseconds
    const differenceInMilliseconds = currentDate - givenDate;

    // Calculate the difference in days
    const differenceInDays = Math.floor(
      differenceInMilliseconds / (1000 * 60 * 60 * 24)
    );

    return differenceInDays;
  }

  const storeManager = [
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      sort: true,
      formatExtraData: loadData,
      formatter: (c, row, i, extra) => {
        return <ActionDropdown data={row} loadData={extra} />;
      },
    },
    {
      dataField: "centerId",
      text: "Group ID",
    },
    {
      dataField: "staffName",
      isDummyField: true,
      text: "Staff Name",
      formatter: (c, row, i, extra) => {
        console.log(row?.joinedData);
        return row?.joinedData[0]?.staffName;
      },
    },
    {
      dataField: "Member Name",
      isDummyField: true,
      text: "Member Name",
      formatter: (c, row, i, extra) => {
        console.log(row?.joinedData);
        return row?.joinedData[0]?.customer;
      },
    },
    // {
    //   dataField: "parent",
    //   isDummyField: true,
    //   text: "Parent Name",
    //   formatter: (c, row, i, extra) => {
    //     console.log(row?.joinedData);
    //     return row?.joinedData[0]?.parent;
    //   },
    // },
    {
      dataField: "mobile",
      isDummyField: true,
      text: "Mobile",
      formatter: (c, row, i, extra) => {
        console.log(row?.joinedData);
        return row?.joinedData[0]?.mobile;
      },
    },
    {
      dataField: "emiAmount",
      isDummyField: true,
      text: "Due Down Payment Amount",
      formatter: (c, row, i, extra) => {
        console.log(row?.joinedData);
        return row?.paidAmount;
      },
    },
    {
      dataField: "Over Due Days",
      isDummyField: true,
      text: "Over Due Days",
      formatter: (c, row, i, extra) => {
        return calculateDaysFromGivenDate(row?.downpaymentDate);
      },
    },
  ];

  const employee = [
    {
      dataField: "centerId",
      text: "Group ID",
    },
    {
      dataField: "staffName",
      isDummyField: true,
      text: "Staff Name",
      formatter: (c, row, i, extra) => {
        console.log(row?.joinedData);
        return row?.joinedData[0]?.staffName;
      },
    },
    {
      dataField: "Member Name",
      isDummyField: true,
      text: "Member Name",
      formatter: (c, row, i, extra) => {
        console.log(row?.joinedData);
        return row?.joinedData[0]?.customer;
      },
    },
    // {
    //   dataField: "parent",
    //   isDummyField: true,
    //   text: "Parent Name",
    //   formatter: (c, row, i, extra) => {
    //     console.log(row?.joinedData);
    //     return row?.joinedData[0]?.parent;
    //   },
    // },
    {
      dataField: "mobile",
      isDummyField: true,
      text: "Mobile",
      formatter: (c, row, i, extra) => {
        console.log(row?.joinedData);
        return row?.joinedData[0]?.mobile;
      },
    },
    {
      dataField: "emiAmount",
      text: "EMI",
    },
    {
      dataField: "emiNumber",
      text: "EMI No.",
    },
    {
      dataField: "Over Due Days",
      isDummyField: true,
      text: "Over Due Days",
      formatter: (c, row, i, extra) => {
        return calculateDaysFromGivenDate(row?.downpaymentDate);
      },
    },
  ];

  const admin = [
    {
      dataField: "storeName",
      isDummyField: true,
      text: "Branch Name",
      formatter: (c, row, i, extra) => {
        console.log(row?.storeData);
        return row?.storeData[0]?.storeName;
      },
    },
    {
      dataField: "centerId",
      text: "Group ID",
    },
    {
      dataField: "staffName",
      isDummyField: true,
      text: "Staff Name",
      formatter: (c, row, i, extra) => {
        console.log(row?.joinedData);
        return row?.joinedData[0]?.staffName;
      },
    },
    {
      dataField: "Member Name",
      isDummyField: true,
      text: "Member Name",
      formatter: (c, row, i, extra) => {
        console.log(row?.joinedData);
        return row?.joinedData[0]?.customer;
      },
    },
    // {
    //   dataField: "parent",
    //   isDummyField: true,
    //   text: "Parent Name",
    //   formatter: (c, row, i, extra) => {
    //     console.log(row?.joinedData);
    //     return row?.joinedData[0]?.parent;
    //   },
    // },
    {
      dataField: "mobile",
      isDummyField: true,
      text: "Mobile",
      formatter: (c, row, i, extra) => {
        console.log(row?.joinedData);
        return row?.joinedData[0]?.mobile;
      },
    },
    {
      dataField: "emiAmount",
      text: "EMI",
    },
    {
      dataField: "emiNumber",
      text: "EMI No.",
    },
    {
      dataField: "Over Due Days",
      isDummyField: true,
      text: "Over Due Days",
      formatter: (c, row, i, extra) => {
        return calculateDaysFromGivenDate(row?.downpaymentDate);
      },
    },
  ];

  let column =
    auth?.user?.role == "admin"
      ? admin
      : auth?.user?.role == "storeManager"
      ? storeManager
      : employee;

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: data?.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };
  return (
    <>
      <Header page="Due Down Payment List" />
      <section className="content">
        <div className="row">
          <div className="col">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Due EMI List</h3>
              </div>
              <div className="card-body">
                
                <br></br>
                <br></br>
                <ToolkitProvider
                  keyField="id"
                  data={data}
                  columns={column}
                  search
                  exportCSV={{
                    fileName: "Duedownpaymentlist.csv",
                  }}
                >
                  {(props) => (
                    <div>
                      
                        {/* <h5>Input something at below input field:</h5> */}
                        
                      <div className="row">
                      <div className="col-lg-8 d-flex">
                        <div id="search_box">
                        <SearchBar {...props.searchProps} />
                        </div>
                        <ExportCSVButton className="mb-2 mx-2" { ...props.csvProps }>Export CSV!!</ExportCSVButton>
                        <button className="mb-2 rounded bg-light" onClick={() => generatePDF(targetRef, {filename: 'Duedownpaymentlist.pdf'})}>Download PDF</button>
                      </div>
                      <div className="col-lg-4">
                      {auth?.user?.role == "storeManager" ? (
                  <>
                    <Link to="/due/add">
                      <button
                        style={{
                          float: "right",
                          width: 160,
                          backgroundColor: "#00c0ef",
                          color: "white",
                          height: 41,
                        }}
                      >
                        <i className="fa fa-plus"></i>
                        {"  New Due EMI"}
                      </button>
                    </Link>
                  </>
                ) : (
                  ""
                )}
                      </div>
                      </div>
                      
                      <div ref={targetRef}>
                      <BootstrapTable
                        wrapperClasses="table-responsive"
                        noDataIndication="Table is Empty"
                        striped
                        {...props.baseProps}
                        pagination={paginationFactory(options)}
                        filter={filterFactory()}
                      />
                      </div>
                      

                      <hr />
                      {/* <BootstrapTable
                        wrapperClasses="table-responsive"
                        noDataIndication="Table is Empty"
                        striped
                        {...props.baseProps}
                        pagination={paginationFactory(options)}
                        filter={filterFactory()}
                      /> */}
                    </div>
                  )}
                </ToolkitProvider>
              </div>
            </div>
          </div>
        </div>

        <StatusToast />
      </section>
    </>
  );
};
export default DueList;
