import React, { useEffect } from "react";
import Header from "../components/header/Header";
import { useState } from "react";
import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import StatusToast from "../components/StatusToast";
import { useLocation } from "react-router-dom";
import swal from "sweetalert";

const AddStore = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location?.state;
  const { auth } = useAuth();

  const [storeName, setstoreName] = useState(data?.storeName || "");
  const [note, setnote] = useState(data?.note || "");
  const [address, setaddress] = useState(data?.address || "");
  const [isSubmit, setisSubmit] = useState(false);

  const submitForm = async () => {
    if (!storeName) {
      return swal({
        title: "Failure!",
        text: `Please Fill Branch Name`,
        icon: "error",
      });
    }
    setisSubmit(true);
    try {
      const id = data?._id;
      const url = id ? "/store/" + id : "/store/add";
      const response = await axios.post(
        url,
        {
          storeName,
          address,
          note,
        },
        {
          headers: { authorization: "bearer " + auth.token },
        }
      );
      console.log(response);
      if (response?.data?.success) {
        swal({
          title: "Success!",
          text: `${response.data.message}`,
          icon: "success",
        });

        navigate("/store/list");
      } else {
        swal({
          title: "Failure!",
          text: `${response.data.message}`,
          icon: "error",
        });
      }
    } catch (err) {
      swal({ title: "Error!", text: `${err.message}`, icon: "error" });

      console.log("this is called " + err);
    } finally {
      setisSubmit(false);
    }
  };

  const handleClose = async () => {
    navigate("/");
  };

  return (
    <>
      <Header page="Add Branch" />
      <section className="content">
        <div className="row">
          <div className="col">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Create New Branch</h3>
              </div>
              <form className="needs-validation" noValidate>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="brand" className="col-sm-2 control-label">
                      Branch Name<label className="text-danger">*</label>
                    </label>
                    <div className="col-md-6">
                      <input
                        type="text"
                        className="form-control input-sm"
                        placeholder="Enter Branch Name"
                        value={storeName}
                        onChange={(e) => {
                          setstoreName(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="description"
                      className="col-sm-2 control-label"
                    >
                      Address
                    </label>
                    <div className="col-md-6">
                      <textarea
                        type="text"
                        className="form-control"
                        placeholder="Enter Store Address"
                        value={address}
                        onChange={(e) => {
                          setaddress(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="description"
                      className="col-sm-2 control-label"
                    >
                      Note
                    </label>
                    <div className="col-md-6">
                      <textarea
                        type="text"
                        className="form-control"
                        placeholder="Enter Note"
                        value={note}
                        onChange={(e) => {
                          setnote(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <center>
                  <div className="card-footer">
                    <input
                      style={{
                        backgroundColor: "#00a65a",
                        color: "white",
                        width: "15%",
                        height: "38px",
                        border: "none",
                        marginRight: "40px",
                      }}
                      type="button"
                      value="Save"
                      name="add_save"
                      disabled={isSubmit}
                      onClick={submitForm}
                    />
                    <input
                      style={{
                        backgroundColor: "#f39c12",
                        color: "white",
                        width: "15%",
                        height: "38px",
                        border: "none",
                        marginRight: "40px",
                      }}
                      type="button"
                      value="Close"
                      name="add_save"
                      onClick={handleClose}
                    />
                  </div>
                  <StatusToast />
                </center>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddStore;
