import React from 'react';
import Header from "../components/header/Header";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search, CSVExport
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";

import { useEffect, useState } from "react";
import axios, { CancelToken } from "../api/axios";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import StatusToast from "../components/StatusToast";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import { toast } from "react-toastify";
import swal from "sweetalert";

import { useRef } from 'react';
import generatePDF from 'react-to-pdf';



const ActionDropdown = ({ data, loadData }) => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const handleDelete = async () => {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
    if (!willDelete) return;
    try {
      let id = data?._id;
      let response = axios.delete("/onboard-form/" + id, {
        headers: { authorization: "Bearer " + auth.token },
      });
      response = await toast.promise(response, {
        pending: "Deleting Entry",
        success: "Entry Deleted Successfully!",
        error: "Something went Wrong!",
      });
      console.log(response.data);

      const cancelToken = CancelToken.source();
      loadData(cancelToken);
    } catch (err) {
      console.log(err);
    }
  };
  const handleEdit = () => {
    console.log(data);
    navigate("/onboard/form/add", { state: data });
  };

  const handleView = () => {
    navigate("/onboard/form/view", { state: data });
  };
  const generateInvoice = async () => {
    const willGenerated = await swal({
      title: "Are you sure?",
      text: "Once You Generate Invoice, you will not be able to Edit This Envoice!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
    if (!willGenerated) return;
    try {
      var itemQuantity = 0;
      var fields = [];
      var arr = data?.data;
      for (var i = 0; i < arr?.length; i++) {
        var item = arr?.[i];
        const {
          productId,
          productName,
          productQuantity,
          productAmount,
          totalAmount,
        } = item;
        itemQuantity = itemQuantity + +productQuantity;

        var item_name = {
          _id: productId,
          productName: productName,
        };

        fields.push({
          item_name,
          item_quantity: productQuantity,
          item_price: productAmount,
          item_tax1_rate: 0,
        });
      }

      var customer = {
        customerName: data?.customer,
        _id: data?.customerId,
      };
      let response = axios.post(
        "/invoice/add",
        {
          customer: customer,
          date: data?.date,
          discount: 0,
          other: data?.proccesingFee,
          total: data?.totalAmount,
          quantity: itemQuantity,
          note: "",
          paymentMode: data?.totalMonthluNumber == 0 ? "Cash" : "EMI",
          downPayment: data?.downPayment,
          monthDuration: data?.totalMonthluNumber,
          data: fields,
        },
        {
          headers: { authorization: "Bearer " + auth.token },
        }
      );
      response = await toast.promise(response, {
        pending: "Invoice Generate.....",
        success: "Envoice Generate Successfully!",
        error: "Something went Wrong!",
      });

      if (response?.data?.success) {
        await axios.post(
          "/onboard-form/generate/invoice",
          {
            onboardFormId: data?._id,
            invoiceId: response?.data?.invoiceId,
          },
          {
            headers: { authorization: "Bearer " + auth.token },
          }
        );
      }

      const cancelToken = CancelToken.source();
      loadData(cancelToken);
    } catch (err) {
      console.log(err);
    }
  };

  const viewInvoice = () => {
    navigate("/onboard/form/invoice/view", { state: data });
  };

  return (
    <>
      <DropdownButton id="dropdown-basic-button" title="Action">
          <>
            <Dropdown.Item onClick={handleEdit}>Edit</Dropdown.Item>
            <Dropdown.Item onClick={handleDelete}>Delete</Dropdown.Item>
          </>
      </DropdownButton>
    </>
  );
};




const OnBoardList = () => {
  const { SearchBar } = Search;
  const [data, setData] = useState([]);
  const { auth } = useAuth();
  const { ExportCSVButton } = CSVExport;
  const targetRef = useRef();
  


  const loadData = async (cancelToken) => {
    try {
      const response = await axios.get("/onboard-form/list", {
        headers: { authorization: "bearer " + auth.token },
        cancelToken: cancelToken.token,
      });
      console.log(response?.data);
      if (response?.data?.success) {
        setData(response.data.list);
      }
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    const cancelToken = CancelToken.source();
    loadData(cancelToken);
  }, []);
  const columns = [
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      sort: true,
      formatExtraData: loadData,
      formatter: (c, row, i, extra) => {
        return <ActionDropdown data={row} loadData={extra} />;
      },
    },
    {
      dataField: "customer",
      text: "आवेदक का नाम ",
    },
    {
      dataField: "center",
      text: "सेंटर का नाम ",
    },
    {
      dataField: "totalAmount",
      text: "कुल भुगतान",
    },
    {
      dataField: "proccesingFee",
      text: "प्रोसेसिंग शुल्क ",
    },
    {
      dataField: "downPayment",
      text: "अग्रिम भुगतान",
    },
    {
      dataField: "totalAddaygiRakam",
      text: "कुल अग्रिम भुगतान",
    },
    {
      dataField: "totalMonthluNumber",
      text: "कुल माह किस्त",
    },
    {
      dataField: "monthlyEMI",
      text: "माह किस्त",
    },
  ];
  const customTotal = (from, to, size) => (
    <>
    <span className="react-bootstrap-table-pagination-total inline-block">
      Showing {from} to {to} of {size} Results
    </span>
         </>                 
  );


  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: data?.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };
  return (
    <>
      <Header page="On-Board List" />
      <section className="content">
        <div className="row">
          <div className="col">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">
                  सद्स्य का विवरण एवं आवास सर्वेक्षण प्रपत्र सूची
                </h3>
              </div>
              <div className="card-body">

                <ToolkitProvider
                  keyField="id"
                  data={data}
                  columns={columns}
                  search
                  exportCSV={{
                    fileName: "Onboardlist.csv",
                  }}
                >
                  {(props) => (
                    <div>
                    <div className='row'>

                      <div className='col-lg-8'>
                      <div className="d-flex ">

                      <div className='mt-2'  id="search_box" >
                        {/* <h5>Input something at below input field:</h5> */}
                        <SearchBar {...props.searchProps} /> 
                      </div>
                      <div className='p-2'>
                        <ExportCSVButton className='mr-2' { ...props.csvProps }>Export CSV!!</ExportCSVButton>
                        <button style={{cursor: "pointer"}} className='btn border float-right rounded' onClick={() => generatePDF(targetRef, {filename: 'Onbordlist.pdf'})}>Download PDF</button>
                        </div>
                      </div>
                      </div>
                      <div className='col-lg-4'>
                      <Link className='mr-2 d-flex justify-content-end'  to="/onboard/form/add">
                      <button style={{ marginTop:"6px", width: 200, backgroundColor: "#00c0ef",color: "white", height: 41,}}>
                    <i className="fa fa-plus"></i>
                    {"  प्रोडक्ट के लिए आवेदन"}
                  </button>
                     </Link>
                      </div>
                      </div>
                  
                      <div ref={targetRef}>
                      <BootstrapTable
                        wrapperClasses="table-responsive"
                        noDataIndication="Table is Empty"
                        striped
                        {...props.baseProps}
                        pagination={paginationFactory(options)}
                        filter={filterFactory()}
                      />
                      
                      </div>

                      <hr />
                      {/* <BootstrapTable
                        wrapperClasses="table-responsive"
                        noDataIndication="Table is Empty"
                        striped
                        {...props.baseProps}
                        pagination={paginationFactory(options)}
                        filter={filterFactory()}
                      /> */}
                    </div>
                  )}
                </ToolkitProvider>
              </div>
            </div>
          </div>
        </div>

        <StatusToast />
      </section>
    </>
  );
};
export default OnBoardList;
