import Header from "../components/header/Header";
import { useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import StatusToast from "../components/StatusToast";
import axios, { CancelToken } from "../api/axios";
import useAuth from "../hooks/useAuth";
import { useEffect } from "react";
import Loader from "../config/loader";

const Home = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { auth } = useAuth();

  const [isLoading, setisLoading] = useState(false);
  const [todaytotalsale, settodaytotalsale] = useState(0);
  const [monthtotalsale, setmonthtotalsale] = useState(0);
  const [totalstore, settotalstore] = useState(0);
  const [totalInvoices, settotalInvoices] = useState(0);
  const [totaltodayinvoices, settotaltodayinvoices] = useState(0);
  const [totalCollection, settotalCollection] = useState(0);
  const [totalDue, settotalDue] = useState(0);
  const [totalDueNo, settotalDueNo] = useState(0);
  const [totalEmiPaid, settotalEmiPaid] = useState(0);
  const [totalCollectionmonthly, settotalCollectionmonthly] = useState(0);
  const [totalDuemonthly, settotalDuemonthly] = useState(0);
  const [totalDueNomonthly, settotalDueNomonthly] = useState(0);
  const [totalEmiPaidmonthly, settotalEmiPaidmonthly] = useState(0);
  const [totaldisbursementsmonthly, settotaldisbursementsmonthly] = useState(0);
  const [totaldisbursements, settotaldisbursements] = useState(0);
  const [totaldownpayment, settotaldownpayment] = useState(0);
  const [totaldownpaymentmonthly, settotaldownpaymentmonthly] = useState(0);
  const [totalupcomingpayment, settotalupcomingpayment] = useState(0);
  const [totaltotalupcomingpaymentmonthly, settotaltotalupcomingpaymentmonthly] = useState(0);
  useEffect(() => {
    const loaderr = async () => {
      setisLoading(true);
      const todayTotalSale = async () => {
        try {
          const response = await axios.post(
            "/report/today-total-sale",
            {},
            {
              headers: { authorization: "bearer " + auth.token },
            }
          );
          if (response.data?.success) {
            settotaltodayinvoices(response.data?.totaltodayinvoices);
            settodaytotalsale(response.data?.totalSale);
          }
        } catch (err) {
          console.log(err);
        }
      };
      const monthTotalSale = async () => {
        try {
          const response = await axios.post(
            "/report/month-total-sale",
            {},
            {
              headers: { authorization: "bearer " + auth.token },
            }
          );
          if (response.data?.success) {
            setmonthtotalsale(response.data?.totalMonthSale);
            settotalInvoices(response.data?.totalInvoices);
          }
        } catch (err) {
          console.log(err);
        }
      };
      const totalStore = async () => {
        try {
          const response = await axios.get("/report/total-store", {
            headers: { authorization: "bearer " + auth.token },
          });
          if (response.data?.success) {
            settotalstore(response.data.totalStore);
          }
        } catch (err) {
          console.log(err);
        }
      };
      const totalCollectionAndDue = async () => {
        try {
          const response = await axios.get("/report/total-collection", {
            headers: { authorization: "bearer " + auth.token },
          });
          if (response.data?.success) {
            settotalCollection(response.data.totalCollection);
            settotalDue(response?.data?.dueCollection);
            settotalDueNo(response.data?.totalDueno);
            settotalEmiPaid(response.data?.totalEmiPaid);
            settotaldisbursements(response.data?.totaldisbursements);
            settotaldownpayment(response.data?.totaldownpayment);
            settotalupcomingpayment(response.data?.upcomingpayments);
          }
        } catch (err) {
          console.log(err);
        }
      };
      const totalCollectionAndDueMonthly = async () => {
        try {
          const response = await axios.get("/report/total-collection-monthly", {
            headers: { authorization: "bearer " + auth.token },
          });
          if (response.data?.success) {
            settotalCollectionmonthly(response.data.totalCollection);
            settotalDuemonthly(response?.data?.dueCollection);
            settotalDueNomonthly(response.data?.totalDueno);
            settotalEmiPaidmonthly(response.data?.totalEmiPaid);
            settotaldisbursementsmonthly(response.data?.totaldisbursements);
            settotaldownpaymentmonthly(response.data?.totaldownpayment);
            settotaltotalupcomingpaymentmonthly(response.data?.upcomingpayments);
          }
        } catch (err) {
          console.log(err);
        }
      };
      await todayTotalSale();
      await monthTotalSale();
      await totalStore();
      await totalCollectionAndDue();
      await totalCollectionAndDueMonthly();
      setisLoading(false);
    };
    loaderr();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : auth?.user?.role != "employee" ? (
        <>
          <Header page="Dashboard" />
          <section className="content">
            <div className="container-fluid">
              <div className="row">
              <div className="col-lg-3 col-3">
                  <div className="small-box bg-danger">
                    <div className="inner">
                      <h3>Rs. {Math.round(totalCollection)}</h3>

                      <p>
                        {auth?.user?.role == "admin"
                          ? "All Store Total Collection"
                          : "Total Collection"}
                      </p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-bag"></i>
                    </div>
                    <Link to="/reports/totalcollection" className="small-box-footer">
                      More info <i className="fas fa-arrow-circle-right"></i>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-3 col-3">
                  <div className="small-box bg-warning">
                    <div className="inner">
                      <h3>
                        Rs. {Math.round(totalDue)}
                        <sup style={{ fontSize: "10px" }}></sup>
                      </h3>

                      <p>
                        {auth?.user?.role == "admin"
                          ? "All Store Total Due Amount"
                          : "Total Due Amount"}
                      </p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-stats-bars"></i>
                    </div>
                    <Link to="/reports/duereports" className="small-box-footer">
                      More info <i className="fas fa-arrow-circle-right"></i>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-3 col-3">
                  <div className="small-box bg-success">
                    <div className="inner">
                      <h3>{totalDueNo}</h3>

                      <p>
                        {auth?.user?.role == "admin"
                          ? "All Store Total Due EMI"
                          : "Total Due EMI"}
                      </p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-person-add"></i>
                    </div>
                    <Link to="/due/list/emi" className="small-box-footer">
                      More info <i className="fas fa-arrow-circle-right"></i>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-3 col-3">
                  <div className="small-box bg-info">
                    <div className="inner">
                      <h3>Rs. {Math.round(totaldisbursements)}</h3>

                      <p>
                        {auth?.user?.role == "admin"
                          ? "All Store Total Paid EMI"
                          : "Total Disbursements"}
                      </p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-person-add"></i>
                    </div>
                    <Link to="/reports/totaldisbursements" className="small-box-footer">
                      More info <i className="fas fa-arrow-circle-right"></i>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-6 col-2">
                  <div className="small-box bg-purple">
                    <div className="inner">
                      <h3>Rs. {Math.round(totaldownpayment)}</h3>

                      <p>
                      Total Downpayment
                      </p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-pie-graph"></i>
                    </div>
                    {auth?.user?.role != "admin" && (
                      <Link to="/reports/totaldisbursements" className="small-box-footer">
                        More info <i className="fas fa-arrow-circle-right"></i>
                      </Link>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 col-2">
                  <div className="small-box bg-blue">
                    <div className="inner">
                      <h3>Rs. {Math.round(totalupcomingpayment)}</h3>

                      <p>
                      Total Upcoming Payments
                      </p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-pie-graph"></i>
                    </div>
                    {auth?.user?.role != "admin" && (
                      <Link to="/reports/totaldisbursements" className="small-box-footer">
                        More info <i className="fas fa-arrow-circle-right"></i>
                      </Link>
                    )}
                  </div>
                </div>
                <div className="col-lg-3 col-3">
                  <div className="small-box bg-info">
                    <div className="inner">
                      <h3>Rs. {Math.round(totalCollectionmonthly)}</h3>

                      <p>
                        {auth?.user?.role == "admin"
                          ? "Monthly Total Collection"
                          : "Monthly Total Collection"}
                      </p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-bag"></i>
                    </div>
                    {auth?.user?.role != "admin" && (
                      <Link to="/reports/totalcollection" className="small-box-footer">
                        More info <i className="fas fa-arrow-circle-right"></i>
                      </Link>
                    )}
                  </div>
                </div>
                <div className="col-lg-3 col-3">
                  <div className="small-box bg-success">
                    <div className="inner">
                      <h3>
                        Rs. {Math.round(totalDuemonthly)}
                        <sup style={{ fontSize: "10px" }}></sup>
                      </h3>

                      <p>
                        {auth?.user?.role == "admin"
                          ? "Monthly Total Due Amount"
                          : "Monthly Total Due Amount"}
                      </p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-stats-bars"></i>
                    </div>
                    {auth?.user?.role != "admin" && (
                      <Link to="/reports/duereports" className="small-box-footer">
                        More info <i className="fas fa-arrow-circle-right"></i>
                      </Link>
                    )}
                  </div>
                </div>
                <div className="col-lg-3 col-3">
                  <div className="small-box bg-warning">
                    <div className="inner">
                      <h3>{Math.round(totalDueNomonthly)}</h3>

                      <p>
                        {auth?.user?.role == "admin"
                          ? "Monthly Total Due EMI"
                          : "Monthly Total Due EMI"}
                      </p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-person-add"></i>
                    </div>
                    {auth?.user?.role != "admin" && (
                      <Link to="/due/list/emi" className="small-box-footer">
                        More info <i className="fas fa-arrow-circle-right"></i>
                      </Link>
                    )}
                  </div>
                </div>
                <div className="col-lg-3 col-3">
                  <div className="small-box bg-danger">
                    <div className="inner">
                      <h3>Rs. {Math.round(totaldisbursementsmonthly)}</h3>

                      <p>
                        {auth?.user?.role == "admin"
                          ? "Monthly Total Disbursements"
                          : "Monthly Total Disbursements"}
                      </p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-pie-graph"></i>
                    </div>
                    {auth?.user?.role != "admin" && (
                      <Link to="/reports/totaldisbursements" className="small-box-footer">
                        More info <i className="fas fa-arrow-circle-right"></i>
                      </Link>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 col-2">
                  <div className="small-box bg-purple">
                    <div className="inner">
                      <h3>Rs. {Math.round(totaldownpaymentmonthly)}</h3>

                      <p>
                       Monthly Total Downpayment
                      </p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-person-add"></i>
                    </div>
                    {auth?.user?.role != "admin" && (
                      <Link to="/reports/totaldisbursements" className="small-box-footer">
                        More info <i className="fas fa-arrow-circle-right"></i>
                      </Link>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 col-2">
                  <div className="small-box bg-blue">
                    <div className="inner">
                      <h3>Rs. {Math.round(totaltotalupcomingpaymentmonthly)}</h3>

                      <p>
                       Monthly Upcoming Payments
                      </p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-person-add"></i>
                    </div>
                    {auth?.user?.role != "admin" && (
                      <Link to="/reports/totaldisbursements" className="small-box-footer">
                        More info <i className="fas fa-arrow-circle-right"></i>
                      </Link>
                    )}
                  </div>
                </div>
                {auth?.user?.role == "admin" ? (
                  <div className="col-lg-12 col-12">
                    <div className="small-box bg-secondary">
                      <div className="inner">
                        <h3>{totalstore}</h3>

                        <p>Total Store</p>
                      </div>
                      <div className="icon">
                        <i className="ion ion-pie-graph"></i>
                      </div>
                      <Link to="/store/list" className="small-box-footer">
                        More info <i className="fas fa-arrow-circle-right"></i>
                      </Link>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          <Header page="Dashboard" />
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-3 col-3">
                  <div className="small-box bg-success">
                    <div className="inner">
                      <h3>Rs. {Math.round(totalCollection)}</h3>

                      <p>
                        {auth?.user?.role == "admin"
                          ? "All Store Total Collection"
                          : "Total Collection"}
                      </p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-bag"></i>
                    </div>
                    <Link to="/paid/emi/list" className="small-box-footer">
                      More info <i className="fas fa-arrow-circle-right"></i>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-3 col-3">
                  <div className="small-box bg-info">
                    <div className="inner">
                      <h3>
                        Rs. {Math.round(totalDue)}
                        <sup style={{ fontSize: "10px" }}></sup>
                      </h3>

                      <p>
                        {auth?.user?.role == "admin"
                          ? "All Store Total Due Amount"
                          : "Total Due Amount"}
                      </p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-stats-bars"></i>
                    </div>
                    <Link to="/due/list" className="small-box-footer">
                      More info <i className="fas fa-arrow-circle-right"></i>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-3 col-3">
                  <div className="small-box bg-warning">
                    <div className="inner">
                      <h3>{Math.round(totalDueNo)}</h3>

                      <p>
                        {auth?.user?.role == "admin"
                          ? "All Store Total Due EMI"
                          : "Total Due EMI"}
                      </p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-person-add"></i>
                    </div>
                    <Link to="/due/list" className="small-box-footer">
                      More info <i className="fas fa-arrow-circle-right"></i>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-3 col-3">
                  <div className="small-box bg-danger">
                    <div className="inner">
                      <h3>{Math.round(totalEmiPaid)}</h3>

                      <p>
                        {auth?.user?.role == "admin"
                          ? "All Store Total Paid EMI"
                          : "Total Paid EMI"}
                      </p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-person-add"></i>
                    </div>
                    <Link to="/paid/emi/list" className="small-box-footer">
                      More info <i className="fas fa-arrow-circle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};
export default Home;
