import React from "react";
import { RotatingLines } from "react-loader-spinner";

const loader = ({ isLoading }) => {
  return (
    <div
      style={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <RotatingLines
        strokeColor="grey"
        strokeWidth="5"
        animationDuration="1.25"
        width="30"
        visible={true}
      />
    </div>
  );
};

export default loader;
