import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
import { useState } from "react";
import { toast } from "react-toastify";
import StatusToast from "../components/StatusToast";
import Loader from "../config/loader";

const CollectionViewSheet = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location?.state;
  console.log(data);
  const { auth } = useAuth();
  const [itemList, setitemList] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const centerId = data?.centerId;
  console.log(itemList);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setisLoading(true);
        const response = await axios.get("/collection-sheet/list/" + centerId, {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        });

        setitemList(response?.data?.list);
      } catch (err) {
        console.log(err.message);
      } finally {
        setisLoading(false);
      }
    };
    fetchData();
  }, []);

  /**
   * Code For Go Reverce Page
   */

  const handleGoBack = () => {
    window.history.back();
  };

  /**
   * End
   */

  const DateForamate = (inputDateString) => {
    if (!inputDateString) {
      return "";
    }
    const parts = inputDateString.split("T")[0].split("-"); // Split and extract date parts
    const year = parts[0];
    const month = parts[1];
    const day = parts[2];

    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  };

  var collectionDate = () => {
    var d = new Date(data?.paymentDate).getDate();
    var m = new Date().getMonth() + 1;
    var y = new Date().getFullYear();

    if (m < 10) {
      m = "0" + m;
    }
    if (d < 10) {
      d = "0" + d;
    }

    return d + "/" + m + "/" + y;
  };

  const itemNames = (data) => {
    console.log(data);
    let itemnames = "";
    data?.map((element) => {
      itemnames = itemnames + element?.productName + " , ";
    });
    return itemnames;
  };

  var targetRef = useRef(null);

  const handlePrint = () => {
    const targetElement = targetRef.current;

    if (targetElement) {
      window.print();
    }
  };

  const totalInstallment = () => {
    let total = 0;
    itemList.map((item) => {
      total = +total + +item?.monthlyEMI;
    });
    return total;
  };

  return (
    <>
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <>
          {/* Header Wrapper Start */}
          <div className="container" id="section-to-print" ref={targetRef}>
            <div
              className="loan-application-main-wrapper"
              style={{
                width: "100%",
                margin: "0 auto",
                border: "1px solid #111",
                padding: "0px 11px",
                // backgroundColor: "#bbdbeb",
                backgroundColor: "white",
              }}
            >
              <div className="">
                <div className="row">
                  <div
                    className="col-lg-12 col-md-12 col-12 mar pad"
                    style={{ padding: 0, margin: 0 }}
                  >
                    <div
                      className="invoice-title"
                      style={{
                        width: "100%",
                        float: "left",
                        textAlign:
                          "center" /* borderBottom: '1px solid #111', */,
                        paddingBottom: 2,
                      }}
                    >
                      <p
                        className="title-heading"
                        style={{
                          fontFamily: '"Roboto", sans-serif',
                          lineHeight: "2.1",
                          color: "#222222",
                          margin: 0,
                          padding: 0,
                          textAlign: "center",
                          fontWeight: 50,
                          textTransform: "uppercase",
                          fontSize: 40,
                        }}
                      >
                        Care Enterprises
                        <hr
                          style={{
                            width: "39%",
                            borderTop: "5px solid #000",
                            marginTop: "-25px",
                          }}
                        />
                      </p>
                      <p
                        style={{
                          // margin: 5,
                          color: "#111",
                          fontWeight: 800 ,
                          // lineHeight: 1,
                          fontSize: 30,
                        }}
                      >
                        Your Necessity is Our Responsibility
                      </p>
                      {/* <br /> */}
                      <h1
                        className="title-heading"
                        style={{
                          fontFamily: '"Roboto", sans-serif',
                          lineHeight: "1.1",
                          color: "white",
                          backgroundColor: "black",
                          margin: 0,
                          padding: 0,
                          textAlign: "center",
                          fontWeight: 600,
                          textTransform: "uppercase",
                          fontSize: 35,
                        }}
                      >
                        Collection Sheet
                      </h1>
                    </div>
                    {/*  */}
                    <div
                      className="di-invoice-main-wrapper"
                      style={{ width: "100%", float: "left" }}
                    >
                      {/* <br /> */}
                      {/* <br /> */}
                      <div className="form-group">
                        {/* <h3
                    className="card-header"
                    style={{ fontFamily: "monospace", fontWeight: "bolder" }}
                  >
                    सेंटर का विवरण
                  </h3> */}

                        {/* <br /> */}
                        <p></p>
                        <div className="row" id={2}>
                          <div className="col-md-6">
                            <label
                              htmlFor="name"
                              style={{
                                fontFamily: '"Roboto", sans-serif',
                                lineHeight: "0.3",
                                color: "#222222",
                                margin: 0,
                                padding: 15,
                                textAlign: "center",
                                fontWeight: 550,
                                fontSize: 25,
                              }}
                            >
                              Collection Date :
                            </label>
                            <span style={{ fontSize: 23 }}>
                              {collectionDate()}
                            </span>
                          </div>
                          <div className="col-md-6">
                            <label
                              htmlFor="name"
                              style={{
                                fontFamily: '"Roboto", sans-serif',
                                lineHeight: "0.3",
                                color: "#222222",
                                margin: 0,
                                padding: 15,
                                textAlign: "center",
                                fontWeight: 550,
                                fontSize: 25,
                              }}
                            >
                              Month No :
                            </label>
                            <span style={{ fontSize: 23 }}>
                              {new Date().getMonth() + 1}
                            </span>
                          </div>
                        </div>
                        <div className="row" id={2}>
                          <div className="col-md-6">
                            <label
                              htmlFor="name"
                              style={{
                                fontFamily: '"Roboto", sans-serif',
                                lineHeight: "0.3",
                                color: "#222222",
                                margin: 0,
                                padding: 15,
                                textAlign: "center",
                                fontWeight: 550,
                                fontSize: 25,
                              }}
                            >
                              Center Name :
                            </label>
                            <span style={{ fontSize: 23 }}>{data?.center}</span>
                          </div>
                          <div className="col-md-6">
                            <label
                              htmlFor="name"
                              style={{
                                fontFamily: '"Roboto", sans-serif',
                                lineHeight: "0.3",
                                color: "#222222",
                                margin: 0,
                                padding: 15,
                                textAlign: "center",
                                fontWeight: 550,
                                fontSize: 25,
                              }}
                            >
                              Staff Name :
                            </label>
                            <span style={{ fontSize: 23 }}>
                              {data?.staffName}
                            </span>
                          </div>
                        </div>
                        <div className="row" id={2}>
                          <div className="col-md-6">
                            <label
                              htmlFor="name"
                              style={{
                                fontFamily: '"Roboto", sans-serif',
                                lineHeight: "0.3",
                                color: "#222222",
                                margin: 0,
                                padding: 15,
                                textAlign: "center",
                                fontWeight: 550,
                                fontSize: 25,
                              }}
                            >
                              Center ID :
                            </label>
                            <span style={{ fontSize: 23 }}>
                              {data?.centerId}
                            </span>
                          </div>
                          <div className="col-md-6">
                            <label
                              htmlFor="name"
                              style={{
                                fontFamily: '"Roboto", sans-serif',
                                lineHeight: "0.3",
                                color: "#222222",
                                margin: 0,
                                padding: 15,
                                textAlign: "center",
                                fontWeight: 550,
                                fontSize: 25,
                              }}
                            >
                              Staff ID :
                            </label>
                            <span style={{ fontSize: 23 }}>
                              {data?.staffId}
                            </span>
                          </div>
                        </div>
                        {/* <div className="row" id={2}>
                          <div className="col-md-6">
                            <label
                              htmlFor="name"
                              style={{
                                fontFamily: '"Roboto", sans-serif',
                                lineHeight: "0.3",
                                color: "#222222",
                                margin: 0,
                                padding: 15,
                                textAlign: "center",
                                fontWeight: 550,
                                fontSize: 25,
                              }}
                            >
                              Disbursement Date :
                            </label>
                            <span style={{ fontSize: 23 }}>
                              {DateForamate(data?.paymentDate)}
                            </span>
                          </div>
                        </div> */}
                        {/* <br /> */}
                        <p></p>
                        {/* <h2
                    className="card-header"
                    style={{
                      fontFamily: '"Roboto", sans-serif',
                      lineHeight: "2.1",
                      color: "#222222",
                      margin: 0,
                      padding: 0,
                      textAlign: "center",
                      fontWeight: 600,
                      textTransform: "uppercase",
                      fontSize: 30,
                    }}
                  >
                    <hr />
                    Installment List
                    <hr />
                  </h2> */}
                        <table
                          className="table table-bordered m-0"
                          style={{
                            marginBottom: "0px",
                            position: "relative",
                            background: "white",
                            width: "100%",
                          }}
                          width="100%"
                        >
                          <thead>
                            <tr style={{ lineHeight: "19px" }}>
                              <th
                                className="w-8"
                                style={{
                                  border: "solid 1px #111111",
                                  background: "#ccc",
                                  WebkitPrintColorAdjust: "exact",
                                  // width: "211px",
                                  textAlign: "center",
                                  lineHeight: "2.0",
                                  fontSize: "15px",
                                  padding: "0px",
                                  padding: "3px 2px",
                                }}
                                align="center"
                              >
                                Sr No.
                              </th>
                              <th
                                className="w-42"
                                style={{
                                  border: "solid 1px #111111",
                                  background: "#ccc",
                                  WebkitPrintColorAdjust: "exact",
                                  // width: "211px",
                                  textAlign: "center",
                                  lineHeight: "2.0",
                                  fontSize: "15px",
                                  padding: "0px",
                                  padding: "3px 2px",
                                }}
                                align="center"
                              >
                                Member Name
                              </th>
                              <th
                                className="w-42"
                                style={{
                                  border: "solid 1px #111111",
                                  background: "#ccc",
                                  WebkitPrintColorAdjust: "exact",
                                  // width: "211px",
                                  textAlign: "center",
                                  lineHeight: "2.0",
                                  fontSize: "15px",
                                  padding: "0px",
                                  padding: "3px 2px",
                                }}
                                align="center"
                              >
                                Product Name
                              </th>
                              <th
                                className="w-22"
                                style={{
                                  border: "solid 1px #111111",
                                  background: "#ccc",
                                  WebkitPrintColorAdjust: "exact",
                                  // width: "211px",
                                  textAlign: "center",
                                  lineHeight: "2.0",
                                  fontSize: "15px",
                                  padding: "0px",
                                  padding: "3px 2px",
                                }}
                                align="center"
                              >
                                Product Amount
                              </th>
                              <th
                                className="w-22"
                                style={{
                                  border: "solid 1px #111111",
                                  background: "#ccc",
                                  WebkitPrintColorAdjust: "exact",
                                  // width: "211px",
                                  textAlign: "center",
                                  lineHeight: "2.0",
                                  fontSize: "15px",
                                  padding: "0px",
                                  padding: "3px 2px",
                                }}
                                align="center"
                              >
                                Down Payment
                              </th>
                              <th
                                className="w-22"
                                style={{
                                  border: "solid 1px #111111",
                                  background: "#ccc",
                                  WebkitPrintColorAdjust: "exact",
                                  // width: "211px",
                                  textAlign: "center",
                                  lineHeight: "2.0",
                                  fontSize: "15px",
                                  padding: "0px",
                                  padding: "3px 2px",
                                }}
                                align="center"
                              >
                                Remaining Amount
                              </th>
                              <th
                                className="w-22"
                                style={{
                                  border: "solid 1px #111111",
                                  background: "#ccc",
                                  WebkitPrintColorAdjust: "exact",
                                  // width: "211px",
                                  textAlign: "center",
                                  lineHeight: "2.0",
                                  fontSize: "15px",
                                  padding: "0px",
                                  padding: "3px 2px",
                                }}
                                align="center"
                              >
                                EMI
                              </th>
                              <th
                                className="w-22"
                                style={{
                                  border: "solid 1px #111111",
                                  background: "#ccc",
                                  WebkitPrintColorAdjust: "exact",
                                  // width: "211px",
                                  textAlign: "center",
                                  lineHeight: "2.0",
                                  fontSize: "15px",
                                  padding: "0px",
                                  padding: "3px 2px",
                                }}
                                align="center"
                              >
                                EMI No.
                              </th>
                              <th
                                className="w-22"
                                colSpan={3}
                                style={{
                                  border: "solid 1px #111111",
                                  background: "#ccc",
                                  WebkitPrintColorAdjust: "exact",
                                  // width: "211px",
                                  textAlign: "center",
                                  lineHeight: "2.0",
                                  fontSize: "15px",
                                  padding: "0px",
                                  padding: "3px 2px",
                                }}
                                align="center"
                              >
                                Var
                              </th>
                            </tr>
                            <tr>
                              <th
                                style={{
                                  border: "solid 1px #111111",
                                  background: "#ccc",
                                  WebkitPrintColorAdjust: "exact",
                                  // width: "0px",
                                  textAlign: "center",
                                  lineHeight: "2.0",
                                  fontSize: "15px",
                                  padding: "0px",
                                  padding: "3px 2px",
                                }}
                                align="center"
                              />
                              <th
                                style={{
                                  border: "solid 1px #111111",
                                  background: "#ccc",
                                  WebkitPrintColorAdjust: "exact",
                                  // width: "0px",
                                  textAlign: "center",
                                  lineHeight: "2.0",
                                  fontSize: "15px",
                                  padding: "0px",
                                  padding: "3px 2px",
                                }}
                                align="center"
                              />
                              <th
                                style={{
                                  border: "solid 1px #111111",
                                  background: "#ccc",
                                  WebkitPrintColorAdjust: "exact",
                                  // width: "0px",
                                  textAlign: "center",
                                  lineHeight: "2.0",
                                  fontSize: "15px",
                                  padding: "0px",
                                  padding: "3px 2px",
                                }}
                                align="center"
                              />
                              <th
                                style={{
                                  border: "solid 1px #111111",
                                  background: "#ccc",
                                  WebkitPrintColorAdjust: "exact",
                                  // width: "0px",
                                  textAlign: "center",
                                  lineHeight: "2.0",
                                  fontSize: "15px",
                                  padding: "0px",
                                  padding: "3px 2px",
                                }}
                                align="center"
                              />
                              <th
                                style={{
                                  border: "solid 1px #111111",
                                  background: "#ccc",
                                  WebkitPrintColorAdjust: "exact",
                                  // width: "0px",
                                  textAlign: "center",
                                  lineHeight: "2.0",
                                  fontSize: "15px",
                                  padding: "0px",
                                  padding: "3px 2px",
                                }}
                                align="center"
                              />
                              <th
                                style={{
                                  border: "solid 1px #111111",
                                  background: "#ccc",
                                  WebkitPrintColorAdjust: "exact",
                                  // width: "0px",
                                  textAlign: "center",
                                  lineHeight: "2.0",
                                  fontSize: "15px",
                                  padding: "0px",
                                  padding: "3px 2px",
                                }}
                                align="center"
                              />
                              <th
                                style={{
                                  border: "solid 1px #111111",
                                  background: "#ccc",
                                  WebkitPrintColorAdjust: "exact",
                                  // width: "0px",
                                  textAlign: "center",
                                  lineHeight: "2.0",
                                  fontSize: "15px",
                                  padding: "0px",
                                  padding: "3px 2px",
                                }}
                                align="center"
                              />
                              <th
                                style={{
                                  border: "solid 1px #111111",
                                  background: "#ccc",
                                  WebkitPrintColorAdjust: "exact",
                                  // width: "0px",
                                  textAlign: "center",
                                  lineHeight: "2.0",
                                  fontSize: "15px",
                                  padding: "0px",
                                  padding: "3px 2px",
                                }}
                                align="center"
                              />
                              <th
                                className="w-8"
                                style={{
                                  border: "solid 1px #111111",
                                  background: "#ccc",
                                  WebkitPrintColorAdjust: "exact",
                                  // width: "100px",
                                  textAlign: "center",
                                  lineHeight: "2.0",
                                  fontSize: "15px",
                                  padding: "0px",
                                  padding: "3px 2px",
                                }}
                                align="center"
                              >
                                REC.
                              </th>
                              <th
                                className="w-8"
                                style={{
                                  border: "solid 1px #111111",
                                  background: "#ccc",
                                  WebkitPrintColorAdjust: "exact",
                                  textAlign: "center",
                                  lineHeight: "2.0",
                                  fontSize: "15px",
                                  padding: "0px",
                                  // width: "100px",
                                  padding: "3px 2px",
                                }}
                                align="center"
                              >
                                ADJ.
                              </th>
                              <th
                                className="w-8"
                                style={{
                                  border: "solid 1px #111111",
                                  background: "#ccc",
                                  WebkitPrintColorAdjust: "exact",
                                  textAlign: "center",
                                  lineHeight: "2.0",
                                  fontSize: "15px",
                                  padding: "0px",
                                  // width: "100px",
                                  padding: "3px 2px",
                                }}
                                align="center"
                              >
                                BAL.
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {itemList?.map((item, index) => (
                              <tr key={index} style={{ lineHeight: "19px" }}>
                                <td
                                  style={{
                                    border: "solid 1px #111111",
                                    fontWeight: 600,
                                    fontSize: "15px",
                                    lineHeight: "62px",
                                    padding: "0px 5px",
                                  }}
                                >
                                  {index + 1}
                                </td>
                                <td
                                  style={{
                                    border: "solid 1px #111111",
                                    fontWeight: 600,
                                    fontSize: "15px",
                                    lineHeight: "25px",
                                    // padding: "0px 5px",
                                  }}
                                >
                                  {item?.customer + " / " + item?.parent }
                                  {/* {"\n"} */}
                                  <br/> 
                                 {item?.mobile}, {item?.parentMobile}
                                </td>
                                <td
                                  style={{
                                    border: "solid 1px #111111",
                                    fontWeight: 600,
                                    fontSize: "15px",
                                    lineHeight: "62px",
                                    padding: "0px 5px",
                                  }}
                                >
                                  {itemNames(item?.data)}
                                </td>
                                <td
                                  style={{
                                    border: "solid 1px #111111",
                                    fontWeight: 600,
                                    fontSize: "15px",
                                    lineHeight: "62px",
                                    padding: "0px 5px",
                                  }}
                                >
                                  {item?.totalAmount}
                                </td>
                                <td
                                  style={{
                                    border: "solid 1px #111111",
                                    fontWeight: 600,
                                    fontSize: "15px",
                                    lineHeight: "62px",
                                    padding: "0px 5px",
                                  }}
                                >
                                  {item?.downPayment}
                                </td>
                                <td
                                  style={{
                                    border: "solid 1px #111111",
                                    fontWeight: 600,
                                    fontSize: "15px",
                                    lineHeight: "62px",
                                    padding: "0px 5px",
                                  }}
                                >
                                  {item?.addaygiRakam}
                                </td>
                                <td
                                  style={{
                                    border: "solid 1px #111111",
                                    fontWeight: 600,
                                    fontSize: "15px",
                                    lineHeight: "62px",
                                    padding: "0px 5px",
                                  }}
                                >
                                  {item?.monthlyEMI}
                                </td>
                                <td
                                  style={{
                                    border: "solid 1px #111111",
                                    fontWeight: 600,
                                    fontSize: "15px",
                                    lineHeight: "62px",
                                    padding: "0px 5px",
                                  }}
                                >
                                  {+item?.totalMonthEmiPaid +
                                    1 +
                                    "-" +
                                    item?.totalMonthluNumber}
                                </td>
                                <td
                                  style={{
                                    border: "solid 1px #111111",
                                    fontWeight: 600,
                                    fontSize: "15px",
                                    lineHeight: "62px",
                                    padding: "0px 5px",
                                  }}
                                >
                                  {item?.res}
                                </td>
                                <td
                                  style={{
                                    border: "solid 1px #111111",
                                    fontWeight: 600,
                                    fontSize: "15px",
                                    lineHeight: "62px",
                                    padding: "0px 5px",
                                  }}
                                >
                                  {item?.advance}
                                </td>
                                <td
                                  style={{
                                    border: "solid 1px #111111",
                                    fontWeight: 600,
                                    fontSize: "15px",
                                    lineHeight: "62px",
                                    padding: "0px 5px",
                                  }}
                                >
                                  {item?.balance}
                                </td>
                                {/* <td
                            style={{
                              border: "solid 1px #111111",
                              fontWeight: 600,
                              fontSize: "15px",
                              lineHeight: "62px",
                              padding: "0px 5px",
                            }}
                          >
                            {+item?.productAmount -
                              +item?.advance -
                              +item?.balance}
                          </td> */}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <center>
                        <div className="row" id={2}>
                          <div className="col-md-12">
                            <label
                              htmlFor="name"
                              style={{
                                fontFamily: '"Roboto", sans-serif',
                                lineHeight: "1.1",
                                color: "#222222",
                                margin: 0,
                                padding: 15,
                                textAlign: "center",
                                fontWeight: 550,
                                fontSize: 25,
                              }}
                            >
                              Total Collection Amount : Rs. {totalInstallment()}
                            </label>
                            <br />
                            {/* Denomination <br />
                            =============== <br />
                            <div style={{ marginRight: "100px" }}>
                              500*
                              <br />
                              200*
                              <br />
                              100*
                              <br />
                              50*
                              <br />
                              20*
                              <br />
                              10*
                              <br />
                              Coin
                              <br />
                            </div>
                            =============== <br />
                            <div style={{ marginRight: "80px" }}>
                              Total = <br />
                            </div>
                            =============== <br /> */}
                          </div>
                        </div>
                      </center>
                      <br />
                      <p>
                        Center Leader Sig.                       Staff Sig.
                                             BM / VF
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <center>
            <button
              className="btn-convert"
              style={{
                outline: "none",
                backgroundColor: "#f44336",
                border: "none",
                color: "white",
                padding: "15px 32px",
                textAlign: "center",
                textDecoration: "none",
                display: "inline-block",
                fontSize: "16px",
                margin: "4px 2px",
                cursor: "pointer",
              }}
              onClick={handlePrint}
            >
              Print On-Board-Form
            </button>
          </center>
          <br />
          <center>
            <button
              className="btn-convert"
              style={{
                outline: "none",
                backgroundColor: "#f44336",
                border: "none",
                color: "white",
                padding: "15px 32px",
                textAlign: "center",
                textDecoration: "none",
                display: "inline-block",
                fontSize: "16px",
                margin: "4px 2px",
                cursor: "pointer",
              }}
              onClick={handleGoBack}
            >
              Go Back
            </button>
          </center>
          <br />
        </>
      )}
    </>
  );
};

export default CollectionViewSheet;
