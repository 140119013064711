import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
import { useState } from "react";
import { toast } from "react-toastify";
import StatusToast from "../components/StatusToast";

const FinalReportCardView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location?.state;
  console.log(location?.state);
  console.log(data);
  const { auth } = useAuth();
  const itemList = data?.data;
  const blankList = [
    { a: "a" },
    { a: "a" },
    { a: "a" },
    { a: "a" },
    { a: "a" },
    { a: "a" },
    { a: "a" },
    { a: "a" },
    { a: "a" },
    { a: "a" },
    { a: "a" },
    { a: "a" },
  ];
  /**
   * Code For Go Reverce Page
   */

  const handleGoBack = () => {
    window.history.back();
  };

  /**
   * End
   */

  const DateForamate = (inputDateString) => {
    if (!inputDateString) {
      return "";
    }
    const parts = inputDateString.split("T")[0].split("-"); // Split and extract date parts
    const year = parts[0];
    const month = parts[1];
    const day = parts[2];

    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  };

  var targetRef = useRef(null);

  const handlePrint = () => {
    const targetElement = targetRef.current;

    if (targetElement) {
      window.print();
    }
  };

  return (
    <>
      {/* Header Wrapper Start */}
      <div className="container" id="section-to-print" ref={targetRef}>
        <div
          className="loan-application-main-wrapper"
          style={{
            width: "100%",
            margin: "0 auto",
            border: "1px solid #111",
            padding: "0px 11px",
            // backgroundColor: "#bbdbeb",
            backgroundColor: "white",
          }}
        >
          <div className="">
            <div className="row">
              <div
                className="col-lg-12 col-md-12 col-12 mar pad"
                style={{ padding: 0, margin: 0 }}
              >
                <div
                  className="invoice-title"
                  style={{
                    width: "100%",
                    float: "left",
                    textAlign: "center" /* borderBottom: '1px solid #111', */,
                    paddingBottom: 2,
                  }}
                >
                  <p
                    className="title-heading"
                    style={{
                      fontFamily: '"Roboto", sans-serif',
                      lineHeight: "2.1",
                      color: "#222222",
                      margin: 0,
                      padding: 0,
                      textAlign: "center",
                      fontWeight: 600,
                      textTransform: "uppercase",
                      fontSize: 65,
                    }}
                  >
                    Care Enterprises
                    <hr
                      style={{
                        width: "70%",
                        borderTop: "7px solid #000",
                        marginTop: "-35px",
                      }}
                    />
                  </p>
                  <p
                    style={{
                      margin: 10,
                      color: "#111",
                      fontWeight: 800 /* lineHeight: 15, */,
                      fontSize: 30,
                    }}
                  >
                    Your Necessity is Our Responsibility
                  </p>
                  <h5
                    className="title-heading"
                    style={{
                      fontFamily: '"Roboto", sans-serif',
                      lineHeight: "1.6",
                      color: "white",
                      backgroundColor: "black",
                      margin: 0,
                      padding: 0,
                      textAlign: "center",
                      fontWeight: 600,
                      textTransform: "uppercase",
                      fontSize: 25,
                    }}
                  >
                    33, लाल बहादुर शास्त्री मार्ग, पंचमपुरा, उज्जैन (म.प्र.)
                    <br />
                    मोबाइल न. लखन सर-7879794525, नीलेश सर-9131132297 <br />
                    कविता मैडम-6265895202, मुकेश सर-9109206324
                  </h5>
                </div>
                {/*  */}
                <div
                  className="di-invoice-main-wrapper"
                  style={{ width: "100%", float: "left" }}
                >
                  <br />
                  <br />
                  <div className="form-group">
                    {/* <h3
                      className="card-header"
                      style={{ fontFamily: "monospace", fontWeight: "bolder" }}
                    >
                      सेंटर का विवरण
                    </h3> */}
                    <div className="row" id={2}>
                      <div className="col-md-8">
                        <label
                          htmlfor="name"
                          style={{
                            fontFamily: '"Roboto", sans-serif',
                            lineHeight: "1.1",
                            color: "#222222",
                            margin: 0,
                            padding: 10,
                            textAlign: "center",
                            fontWeight: 550,
                            textTransform: "uppercase",
                            fontSize: 27,
                          }}
                        >
                          Date :{DateForamate(data?.date)}
                        </label>
                      </div>

                      <div className="col-md-4">
                        <label
                          htmlfor="name"
                          style={{
                            fontFamily: '"Roboto", sans-serif',
                            lineHeight: "1.1",
                            color: "#222222",
                            margin: 0,
                            padding: 10,
                            textAlign: "center",
                            fontWeight: 550,
                            textTransform: "uppercase",
                            fontSize: 27,
                          }}
                        >
                          राजि. न. C/1751701
                        </label>
                        {data?.district}
                      </div>
                    </div>
                    <br />
                    <div className="row" id={2}>
                      <div className="col-md-4">
                        <label
                          htmlfor="name"
                          style={{
                            fontFamily: '"Roboto", sans-serif',
                            lineHeight: "1.1",
                            color: "#222222",
                            margin: 0,
                            padding: 15,
                            textAlign: "center",
                            fontWeight: 550,
                            fontSize: 25,
                          }}
                        >
                          सदस्य का नाम :
                        </label>
                        <span style={{ fontSize: 23 }}>
                          {data?.customerName}
                        </span>
                      </div>
                      <div className="col-md-5">
                        <label
                          htmlfor="name"
                          style={{
                            fontFamily: '"Roboto", sans-serif',
                            lineHeight: "1.1",
                            color: "#222222",
                            margin: 0,
                            padding: 15,
                            textAlign: "center",
                            fontWeight: 550,
                            fontSize: 25,
                          }}
                        >
                          पिता/पति/पुत्र का नाम :
                        </label>
                        <span style={{ fontSize: 23 }}>{data?.parentName}</span>
                      </div>
                      <div className="col-md-3">
                        <label
                          htmlfor="name"
                          style={{
                            fontFamily: '"Roboto", sans-serif',
                            lineHeight: "1.1",
                            color: "#222222",
                            margin: 0,
                            padding: 15,
                            textAlign: "center",
                            fontWeight: 550,
                            fontSize: 25,
                          }}
                        >
                          सदस्य की उम्र :
                        </label>
                        <span style={{ fontSize: 23 }}>
                          {data?.customerAge}
                        </span>
                      </div>
                    </div>
                    <div className="row" id={2}>
                      <div className="col-md-4">
                        <label
                          htmlfor="name"
                          style={{
                            fontFamily: '"Roboto", sans-serif',
                            lineHeight: "1.1",
                            color: "#222222",
                            margin: 0,
                            padding: 15,
                            textAlign: "center",
                            fontWeight: 550,
                            fontSize: 25,
                          }}
                        >
                          सेंटर का नाम :
                        </label>
                        <span style={{ fontSize: 23 }}>{data?.center}</span>
                      </div>
                      <div className="col-md-5">
                        <label
                          htmlfor="name"
                          style={{
                            fontFamily: '"Roboto", sans-serif',
                            lineHeight: "1.1",
                            color: "#222222",
                            margin: 0,
                            padding: 15,
                            textAlign: "center",
                            fontWeight: 550,
                            fontSize: 25,
                          }}
                        >
                          सेंटर की आईडी :
                        </label>
                        <span style={{ fontSize: 23 }}>{data?.centerId}</span>
                      </div>
                    </div>
                    <div className="row" id={2}>
                      <div className="col-md-6">
                        <label
                          htmlfor="name"
                          style={{
                            fontFamily: '"Roboto", sans-serif',
                            lineHeight: "1.1",
                            color: "#222222",
                            margin: 0,
                            padding: 15,
                            textAlign: "center",
                            fontWeight: 550,
                            fontSize: 25,
                          }}
                        >
                          सदस्यता ग्रहण की दिनांक :
                        </label>
                        <span style={{ fontSize: 23 }}>
                          {DateForamate(data?.sadasyaGrahanDate)}
                        </span>
                      </div>
                    </div>
                    <h2
                      className="card-header"
                      style={{
                        fontFamily: '"Roboto", sans-serif',
                        lineHeight: "0.8",
                        color: "#222222",
                        margin: 0,
                        padding: 0,
                        textAlign: "center",
                        fontWeight: 600,
                        textTransform: "uppercase",
                        fontSize: 27,
                      }}
                    >
                      <hr />
                      प्रोडक्ट का विवरण
                      <hr />
                    </h2>
                    <table
                      className="table table-bordered m-0"
                      style={{
                        marginBottom: "0px",
                        position: "relative",
                        background: "white",
                        width: "100%",
                      }}
                      width="100%"
                    >
                      <thead>
                        <tr style={{ lineHeight: "19px" }}>
                          <th
                            className="w-8"
                            style={{
                              border: "solid 1px #111111",
                              background: "#ccc",
                              WebkitPrintColorAdjust: "exact",
                              // width: "211px",
                              textAlign: "center",
                              lineHeight: "2.0",
                              fontSize: "15px",
                              padding: "0px",
                              padding: "3px 2px",
                            }}
                            align="center"
                          >
                            क्र.स.
                          </th>
                          <th
                            className="w-42"
                            style={{
                              border: "solid 1px #111111",
                              background: "#ccc",
                              WebkitPrintColorAdjust: "exact",
                              // width: "211px",
                              textAlign: "center",
                              lineHeight: "2.0",
                              fontSize: "15px",
                              padding: "0px",
                              padding: "3px 2px",
                            }}
                            align="center"
                          >
                            प्रोडक्ट का नाम
                          </th>
                          <th
                            className="w-12"
                            style={{
                              border: "solid 1px #111111",
                              background: "#ccc",
                              WebkitPrintColorAdjust: "exact",
                              // width: "211px",
                              textAlign: "center",
                              lineHeight: "2.0",
                              fontSize: "15px",
                              padding: "0px",
                              padding: "3px 2px",
                            }}
                            align="center"
                          >
                            प्रोडक्ट का मूल्य
                          </th>
                          <th
                            className="w-12"
                            style={{
                              border: "solid 1px #111111",
                              background: "#ccc",
                              WebkitPrintColorAdjust: "exact",
                              // width: "211px",
                              textAlign: "center",
                              lineHeight: "2.0",
                              fontSize: "15px",
                              padding: "0px",
                              padding: "3px 2px",
                            }}
                            align="center"
                          >
                            जमा राशि
                          </th>
                          <th
                            className="w-12"
                            style={{
                              border: "solid 1px #111111",
                              background: "#ccc",
                              WebkitPrintColorAdjust: "exact",
                              // width: "211px",
                              textAlign: "center",
                              lineHeight: "2.0",
                              fontSize: "15px",
                              padding: "0px",
                              padding: "3px 2px",
                            }}
                            align="center"
                          >
                            अदायगी रकम{" "}
                          </th>
                          <th
                            className="w-22"
                            style={{
                              border: "solid 1px #111111",
                              background: "#ccc",
                              WebkitPrintColorAdjust: "exact",
                              // width: "211px",
                              textAlign: "center",
                              lineHeight: "2.0",
                              fontSize: "15px",
                              padding: "0px",
                              padding: "3px 2px",
                            }}
                            align="center"
                          >
                            कुल किस्ते
                          </th>
                          <th
                            style={{
                              border: "solid 1px #111111",
                              background: "#ccc",
                              WebkitPrintColorAdjust: "exact",
                              textAlign: "center",
                              lineHeight: "2.0",
                              fontSize: "15px",
                              padding: "0px",
                              // width: "250px",
                              padding: "3px 2px",
                            }}
                            align="center"
                          >
                            मासिक किस्त
                          </th>
                          {/* <th
                            style={{
                              border: "solid 1px #111111",
                              background: "#ccc",
                              WebkitPrintColorAdjust: "exact",
                              textAlign: "center",
                              lineHeight: "2.0",
                              fontSize: "15px",
                              padding: "0px",
                              // width: "250px",
                              padding: "3px 2px",
                            }}
                            align="center"
                          >
                            Bal.
                          </th>
                          <th
                            className="w-42"
                            style={{
                              border: "solid 1px #111111",
                              background: "#ccc",
                              WebkitPrintColorAdjust: "exact",
                              // width: "211px",
                              textAlign: "center",
                              lineHeight: "2.0",
                              fontSize: "15px",
                              padding: "0px",
                              padding: "3px 2px",
                            }}
                            align="center"
                          >
                            Total
                          </th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {itemList?.map((item, index) => (
                          <tr key={index} style={{ lineHeight: "19px" }}>
                            <td
                              style={{
                                border: "solid 1px #111111",
                                fontWeight: 600,
                                fontSize: "25px",
                                lineHeight: "35px",
                                padding: "0px 5px",
                              }}
                            >
                              {index + 1}
                            </td>

                            <td
                              style={{
                                border: "solid 1px #111111",
                                fontWeight: 600,
                                fontSize: "25px",
                                lineHeight: "35px",
                                padding: "0px 5px",
                              }}
                            >
                              {item?.productName}
                            </td>
                            <td
                              style={{
                                border: "solid 1px #111111",
                                fontWeight: 600,
                                fontSize: "25px",
                                lineHeight: "35px",
                                padding: "0px 5px",
                              }}
                            >
                              {item?.productAmount}
                            </td>
                            <td
                              style={{
                                border: "solid 1px #111111",
                                fontWeight: 600,
                                fontSize: "25px",
                                lineHeight: "35px",
                                padding: "0px 5px",
                              }}
                            >
                              {item?.depositAmount}
                            </td>
                            <td
                              style={{
                                border: "solid 1px #111111",
                                fontWeight: 600,
                                fontSize: "25px",
                                lineHeight: "35px",
                                padding: "0px 5px",
                              }}
                            >
                              {+item?.productAmount - +item?.depositAmount}
                            </td>
                            <td
                              style={{
                                border: "solid 1px #111111",
                                fontWeight: 600,
                                fontSize: "25px",
                                lineHeight: "35px",
                                padding: "0px 5px",
                              }}
                            >
                              {item?.totalInstallment}
                            </td>
                            <td
                              style={{
                                border: "solid 1px #111111",
                                fontWeight: 600,
                                fontSize: "25px",
                                lineHeight: "35px",
                                padding: "0px 5px",
                              }}
                            >
                              {Math.round(
                                (+item?.productAmount - +item?.depositAmount) /
                                  item?.totalInstallment
                              )}
                            </td>
                            {/* <td
                              style={{
                                border: "solid 1px #111111",
                                fontWeight: 600,
                                fontSize: "25px",
                                lineHeight: "35px",
                                padding: "0px 5px",
                              }}
                            >
                              {item?.balance}
                            </td>
                            <td
                              style={{
                                border: "solid 1px #111111",
                                fontWeight: 600,
                                fontSize: "25px",
                                lineHeight: "35px",
                                padding: "0px 5px",
                              }}
                            >
                              {+item?.productAmount -
                                +item?.advance -
                                +item?.balance}
                            </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <h2
                      className="card-header"
                      style={{
                        fontFamily: '"Roboto", sans-serif',
                        lineHeight: "0.8",
                        color: "#222222",
                        margin: 0,
                        padding: 0,
                        textAlign: "center",
                        fontWeight: 600,
                        textTransform: "uppercase",
                        fontSize: 27,
                      }}
                    >
                      <hr />
                      मासिक किस्त का विवरण
                      <hr />
                    </h2>
                    <table
                      className="table table-bordered m-0"
                      style={{
                        marginBottom: "0px",
                        position: "relative",
                        background: "white",
                        width: "100%",
                      }}
                      width="100%"
                    >
                      <thead>
                        <tr style={{ lineHeight: "19px" }}>
                          <th
                            className="w-12"
                            style={{
                              border: "solid 1px #111111",
                              background: "#ccc",
                              WebkitPrintColorAdjust: "exact",
                              width: "130px",
                              textAlign: "center",
                              lineHeight: "2.0",
                              fontSize: "15px",
                              padding: "0px",
                              padding: "3px 2px",
                            }}
                            align="center"
                          >
                            दिनांक
                          </th>
                          <th
                            className="w-8"
                            style={{
                              border: "solid 1px #111111",
                              background: "#ccc",
                              WebkitPrintColorAdjust: "exact",
                              width: "80px",
                              textAlign: "center",
                              lineHeight: "2.0",
                              fontSize: "15px",
                              padding: "0px",
                              padding: "3px 2px",
                            }}
                            align="center"
                          >
                            माह संख्या
                          </th>
                          <th
                            className="w-12"
                            style={{
                              border: "solid 1px #111111",
                              background: "#ccc",
                              WebkitPrintColorAdjust: "exact",
                              width: "140px",
                              textAlign: "center",
                              lineHeight: "2.0",
                              fontSize: "15px",
                              padding: "0px",
                              padding: "3px 2px",
                            }}
                            align="center"
                          >
                            चुकाई गई किस्त
                          </th>
                          <th
                            colspan={3}
                            className="w-42 "
                            style={{
                              border: "solid 1px #111111",
                              background: "#ccc",
                              WebkitPrintColorAdjust: "exact",
                              width: "300px",
                              textAlign: "center",
                              lineHeight: "2.0",
                              fontSize: "15px",
                              padding: "0px",
                              padding: "3px 2px",
                            }}
                            align="center"
                          >
                            अग्रिम किस्त{" "}
                          </th>
                          <th
                            className="w-12"
                            style={{
                              border: "solid 1px #111111",
                              background: "#ccc",
                              width: "140px",
                              WebkitPrintColorAdjust: "exact",
                              textAlign: "center",
                              lineHeight: "2.0",
                              fontSize: "15px",
                              padding: "0px",
                              padding: "3px 2px",
                            }}
                            align="center"
                          >
                            केंद्र प्रबंधक के हस्ताचार
                          </th>
                        </tr>
                        <tr>
                          <th
                            style={{
                              border: "solid 1px #111111",
                              background: "#ccc",
                              WebkitPrintColorAdjust: "exact",
                              width: "0px",
                              textAlign: "center",
                              lineHeight: "2.0",
                              fontSize: "15px",
                              padding: "0px",
                              padding: "3px 2px",
                            }}
                            align="center"
                          />
                          <th
                            style={{
                              border: "solid 1px #111111",
                              background: "#ccc",
                              WebkitPrintColorAdjust: "exact",
                              width: "0px",
                              textAlign: "center",
                              lineHeight: "2.0",
                              fontSize: "15px",
                              padding: "0px",
                              padding: "3px 2px",
                            }}
                            align="center"
                          />
                          <th
                            style={{
                              border: "solid 1px #111111",
                              background: "#ccc",
                              WebkitPrintColorAdjust: "exact",
                              width: "0px",
                              textAlign: "center",
                              lineHeight: "2.0",
                              fontSize: "15px",
                              padding: "0px",
                              padding: "3px 2px",
                            }}
                            align="center"
                          />
                          <th
                            className="w-12"
                            style={{
                              border: "solid 1px #111111",
                              background: "#ccc",
                              WebkitPrintColorAdjust: "exact",
                              width: "100px",
                              textAlign: "center",
                              lineHeight: "2.0",
                              fontSize: "15px",
                              padding: "0px",
                              padding: "3px 2px",
                            }}
                            align="center"
                          >
                            जमा
                          </th>
                          <th
                            className="w-12"
                            style={{
                              border: "solid 1px #111111",
                              background: "#ccc",
                              WebkitPrintColorAdjust: "exact",
                              textAlign: "center",
                              lineHeight: "2.0",
                              fontSize: "15px",
                              padding: "0px",
                              width: "100px",
                              padding: "3px 2px",
                            }}
                            align="center"
                          >
                            सामायोजीत
                          </th>
                          <th
                            className="w-12"
                            style={{
                              border: "solid 1px #111111",
                              background: "#ccc",
                              WebkitPrintColorAdjust: "exact",
                              textAlign: "center",
                              lineHeight: "2.0",
                              fontSize: "15px",
                              padding: "0px",
                              width: "100px",
                              padding: "3px 2px",
                            }}
                            align="center"
                          >
                            शेष
                          </th>
                          <th
                            style={{
                              border: "solid 1px #111111",
                              background: "#ccc",
                              WebkitPrintColorAdjust: "exact",
                              // width: "211px",
                              textAlign: "center",
                              lineHeight: "2.0",
                              fontSize: "15px",
                              padding: "0px",
                              padding: "3px 2px",
                            }}
                            align="center"
                          />
                        </tr>
                      </thead>

                      <tbody>
                        {blankList?.map((item, index) => (
                          <tr key={index} style={{ lineHeight: "19px" }}>
                            <td
                              style={{
                                border: "solid 1px #111111",
                                fontWeight: 600,
                                fontSize: "25px",
                                lineHeight: "35px",
                                padding: "0px 5px",
                              }}
                            >
                              {item?.arfg}
                            </td>
                            <td
                              style={{
                                border: "solid 1px #111111",
                                fontWeight: 600,
                                fontSize: "25px",
                                lineHeight: "35px",
                                padding: "0px 5px",
                              }}
                            >
                              {index + 1}
                            </td>
                            <td
                              style={{
                                border: "solid 1px #111111",
                                fontWeight: 600,
                                fontSize: "25px",
                                lineHeight: "35px",
                                padding: "0px 5px",
                              }}
                            >
                              {item?.asfasf}
                            </td>
                            <td
                              style={{
                                border: "solid 1px #111111",
                                fontWeight: 600,
                                fontSize: "25px",
                                lineHeight: "35px",
                                padding: "0px 5px",
                              }}
                            >
                              {item?.jhfgjhg}
                            </td>
                            <td
                              style={{
                                border: "solid 1px #111111",
                                fontWeight: 600,
                                fontSize: "25px",
                                lineHeight: "35px",
                                padding: "0px 5px",
                              }}
                            >
                              {item?.hkjj}
                            </td>
                            <td
                              style={{
                                border: "solid 1px #111111",
                                fontWeight: 600,
                                fontSize: "25px",
                                lineHeight: "35px",
                                padding: "0px 5px",
                              }}
                            >
                              {item?.hjhk}
                            </td>
                            <td
                              style={{
                                border: "solid 1px #111111",
                                fontWeight: 600,
                                fontSize: "25px",
                                lineHeight: "35px",
                                padding: "0px 5px",
                              }}
                            >
                              {item?.gh}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <center>
        <button
          className="btn-convert"
          style={{
            outline: "none",
            backgroundColor: "#f44336",
            border: "none",
            color: "white",
            padding: "15px 32px",
            textAlign: "center",
            textDecoration: "none",
            display: "inline-block",
            fontSize: "16px",
            margin: "4px 2px",
            cursor: "pointer",
          }}
          onClick={handlePrint}
        >
          Print Final Report Card
        </button>
      </center>
      <br />
      <center>
        <button
          className="btn-convert"
          style={{
            outline: "none",
            backgroundColor: "#f44336",
            border: "none",
            color: "white",
            padding: "15px 32px",
            textAlign: "center",
            textDecoration: "none",
            display: "inline-block",
            fontSize: "16px",
            margin: "4px 2px",
            cursor: "pointer",
          }}
          onClick={handleGoBack}
        >
          Go Back
        </button>
      </center>
      <br />
    </>
  );
};

export default FinalReportCardView;
