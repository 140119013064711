import React from "react";
import { TailSpin } from "react-loader-spinner";

const loader = ({ isLoading }) => {
  return (
    <div
      style={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        marginTop: "200px",
      }}
    >
      <TailSpin
        height="100"
        width="100"
        color="#6c757d"
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
        visible={isLoading}
      />
    </div>
  );
};

export default loader;
