const menu = [
  {
    name: "On Board Form",
    icon: "fa-solid fa-fill text-aqua",
    roles: ["storeManager", "employee"],
    children: [
      {
        name: "Add",
        icon: "fa fa-plus-square nav-icon",
        path: "/onboard/form/add",
      },
      {
        name: "List",
        icon: "fa fa-list-ol nav-icon",
        path: "/onboard/form/list",
      },
    ],
  },
  {
    name: "Collection Sheet",
    icon: "fas fa-file-invoice-dollar text-aqua",
    // icon: "fas fa-money-check-alt text-aqua",
    roles: ["storeManager", "employee"],
    children: [
      // {
      //   name: "Add",
      //   icon: "fa fa-plus-square nav-icon",
      //   path: "/collection/sheet/add",
      // },
      {
        name: "List",
        icon: "fa fa-list-ol nav-icon",
        path: "/collection/sheet/list",
      },
    ],
  },
  {
    name: "Due Details",
    icon: "fa fa-window-close text-aqua",
    roles: ["storeManager", "employee"],
    children: [
      {
        name: "Add Due EMI",
        icon: "fa fa-plus-square nav-icon",
        path: "/due/add/emi",
        roles: ["storeManager"],
      },
      {
        name: "Add Due Down Payment",
        icon: "fa fa-plus-square nav-icon",
        path: "/due/add/downpayment",
        roles: ["storeManager"],
      },
      {
        name: "Due EMI List",
        icon: "fa fa-list-ol nav-icon",
        path: "/due/list/emi",
        roles: ["storeManager", "employee"],
      },
     
      {
        name: "Due Down Payment List",
        icon: "fa fa-list-ol nav-icon",
        path: "/due/list/downpayment",
        roles: ["storeManager", "employee"],
      }
    ],
  },
  // {
  //   name: "Paid EMI Details",
  //   icon: "fa fa-check-square text-aqua",
  //   roles: ["storeManager", "employee"],
  //   children: [
  //     {
  //       name: "Paid EMI List",
  //       icon: "fa fa-list-ol nav-icon",
  //       path: "/paid/emi/list",
  //     },
  //   ],
  // },
  // {
  //   name: "Customers",
  //   icon: "fa fa-user-circle text-aqua",
  //   roles: ["storeManager"],
  //   children: [
  //     {
  //       name: "Add Customer",
  //       icon: "fa fa-plus-square nav-icon",
  //       path: "/customer/add",
  //     },
  //     {
  //       name: "Customer List",
  //       icon: "fa fa-list-ol nav-icon",
  //       path: "/customer/list",
  //     },
  //   ],
  // },
  // {
  //   name: "Invoice",
  //   icon: "fas fa-file-invoice text-aqua",
  //   roles: ["storeManager"],
  //   children: [
  //     {
  //       name: "Create Invoice",
  //       icon: "fa fa-plus-square nav-icon",
  //       path: "/invoice/add",
  //     },
  //     {
  //       name: "Invoice List",
  //       icon: "fa fa-list-ol nav-icon",
  //       path: "/invoice/list",
  //     },
  //   ],
  // },
  {
    name: "Products",
    icon: "fas fa-folder-plus	 text-aqua",
    roles: ["storeManager"],
    children: [
      {
        name: "Add Product",
        icon: "fa fa-plus-square nav-icon",
        path: "/product/add",
      },
      {
        name: "Product List",
        icon: "fa fa-list-ol nav-icon",
        path: "/product/list",
      },
      {
        name: "Add Category",
        icon: "fa fa-plus-square nav-icon",
        path: "/category/add",
      },
      {
        name: "Category List",
        icon: "fa fa-list-ol nav-icon",
        path: "/category/list",
      },
    ],
  },
  // {
  //   name: "Final Report Card",
  //   icon: "fa fa-address-card text-aqua",
  //   roles: ["storeManager"],
  //   children: [
  //     {
  //       name: "Add Final Report Card",
  //       icon: "fa fa-plus-square nav-icon",
  //       path: "/final/reportCard/add",
  //     },
  //     {
  //       name: "Final Report Card List",
  //       icon: "fa fa-list-ol nav-icon",
  //       path: "/final/reportCard/list",
  //     },
  //   ],
  // },
  {
    name: "Reports",
    icon: "fa fa-clone",
    roles: ["admin","storeManager"],
    children: [
      {
        name: "Total Disbursements",
        icon: "fa fa-plus-square nav-icon",
        path: "/reports/totaldisbursements",
        roles: ["admin","storeManager"],
      },
      {
        name: "Total Collection",
        icon: "fa fa-plus-square nav-icon",
        path: "/reports/totalcollection",
        roles: ["admin","storeManager"],
      },
      {
        name: "Due Reports",
        icon: "fa fa-plus-square nav-icon",
        path: "/reports/duereports",
        roles: ["admin","storeManager"],
      },
      {
        name: "Stock reports",
        icon: "fa fa-plus-square nav-icon",
        path: "/reports/stockreports",
        roles: ["admin","storeManager"],
      },
    ],
  },
  {
    name: "Setting",
    icon: "fa fa-cog",
    roles: ["admin", "storeManager", "employee"],
    children: [
      {
        name: "Add User",
        icon: "fa fa-plus-square nav-icon",
        path: "/user/add",
        roles: ["admin", "storeManager"],
      },
      {
        name: "User List",
        icon: "fa fa-list-ol nav-icon",
        path: "/user/list",
        roles: ["admin", "storeManager"],
      },
      {
        name: "Add Branch",
        icon: "fa fa-plus-square nav-icon",
        path: "/store/add",
        roles: ["admin"],
      },
      {
        name: "Branch List",
        icon: "fa fa-list-ol nav-icon",
        path: "/store/list",
        roles: ["admin"],
      },
      {
        name: "Add Group",
        icon: "fa fa-plus-square nav-icon",
        path: "/center/add",
        roles: ["storeManager", "employee"],
      },
      {
        name: "Group List",
        icon: "fa fa-list-ol nav-icon",
        path: "/center/list",
        roles: ["storeManager", "employee"],
      },
    ],
  },
];
export default menu;
