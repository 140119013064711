import { useRef, useState } from "react";
import Header from "../components/header/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import StatusToast from "../components/StatusToast";
import axios, { CancelToken } from "../api/axios";
import useAuth from "../hooks/useAuth";
import { useEffect } from "react";
import Select from "react-dropdown-select";

const AddCustomer = () => {
  const location = useLocation();
  const data = location.state;
  const [customerName, setcustomerName] = useState(data?.customerName || "");
  const [parentName, setparentName] = useState(data?.parentName || "");
  const [mobile, setmobile] = useState(data?.mobile || "");
  const [customerAge, setcustomerAge] = useState(data?.customerAge || "");
  const [monthlyIncome, setmonthlyIncome] = useState(data?.monthlyIncome || "");
  const [address, setaddress] = useState(data?.address || "");

  const navigate = useNavigate();
  const [isSubmit, setisSubmit] = useState(false);
  const { auth } = useAuth();

  const handleClose = async () => {
    navigate("/");
  };

  const addCustomer = async () => {
    if (!customerName || !mobile || !customerAge || !parentName || !address) {
      return swal({
        title: "Failure!",
        text: `Please Fill All Fields`,
        icon: "error",
      });
    }

    if (isSubmit) return;
    setisSubmit(true);

    try {
      const id = data?._id;
      const url = data?._id ? "/customer/" + id : "/customer/add";
      const response = await axios.post(
        url,
        {
          customerName,
          parentName,
          mobile,
          customerAge,
          address,
          monthlyIncome,
        },
        {
          headers: { authorization: "bearer " + auth.token },
        }
      );
      console.log(response);
      if (response?.data?.success) {
        swal({
          title: "Success!",
          text: `${response.data.message}`,
          icon: "success",
        });

        navigate("/customer/list");
      } else {
        swal({
          title: "Failure!",
          text: `${response.data.message}`,
          icon: "error",
        });
      }
    } catch (err) {
      swal({ title: "Error!", text: `${err.message}`, icon: "error" });
    } finally {
      setisSubmit(false);
    }
  };

  return (
    <>
      <Header page="Add Customer" />
      <section className="content">
        <div className="row">
          <div className="col">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Create Customer</h3>
              </div>
              <form className="needs-validation" noValidate>
                <div className="card-body">
                  <div className="form-group">
                    <div className="row" id="2">
                      <div className="col-md-3">
                        <label htmlFor="name">Customer Name:</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Enter Customer Name"
                          value={customerName}
                          onChange={(e) => setcustomerName(e.target.value)}
                        />
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="username">ParentName: </label>
                        <input
                          type="text"
                          className="form-control"
                          id="text"
                          placeholder="Enter Parent Name"
                          value={parentName}
                          onChange={(e) => setparentName(e.target.value)}
                        />
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="mobile">Mobile:</label>
                        <input
                          required
                          type="number"
                          className="form-control"
                          id="mobile"
                          value={mobile}
                          placeholder="Enter Mobile "
                          onChange={(e) => setmobile(e.target.value)}
                        />
                      </div>

                      <div className="col-md-3">
                        <label htmlFor="whom">Customer Age:</label>
                        <input
                          required
                          type="number"
                          className="form-control"
                          id="customerAge"
                          placeholder="Enter Customer Age"
                          value={customerAge}
                          onChange={(e) => setcustomerAge(e.target.value)}
                        />
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="whom">Monthly Income:</label>
                        <input
                          required
                          type="number"
                          className="form-control"
                          id="monthly Income"
                          placeholder="Enter Monthly Income"
                          value={monthlyIncome}
                          onChange={(e) => setmonthlyIncome(e.target.value)}
                        />
                      </div>
                      <br />
                      <br />
                      <br />
                      <br />

                      <div className="col-md-3">
                        <label htmlFor="name">Customer Address:</label>
                        <textarea
                          required
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Customer Address"
                          value={address}
                          onChange={(e) => setaddress(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <center>
                  <div className="card-footer">
                    <input
                      style={{
                        backgroundColor: "#00a65a",
                        color: "white",
                        width: "15%",
                        height: "38px",
                        border: "none",
                        marginRight: "40px",
                      }}
                      type="button"
                      value="Save"
                      name="add_save"
                      disabled={isSubmit}
                      onClick={addCustomer}
                    />
                    <input
                      style={{
                        backgroundColor: "#f39c12",
                        color: "white",
                        width: "15%",
                        height: "38px",
                        border: "none",
                        marginRight: "40px",
                      }}
                      type="button"
                      value="Close"
                      name="add_save"
                      onClick={handleClose}
                    />
                  </div>
                  <StatusToast />
                </center>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default AddCustomer;
