import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
import { useState } from "react";
import { toast } from "react-toastify";
import StatusToast from "../components/StatusToast";

const ViewInvoice = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location?.state;
  console.log(data);
  const invoiceId = data?.invoiceId;
  const [invoiceData, setInvoiceData] = useState();
  const { auth } = useAuth();
  console.log(auth);
  useEffect(() => {
    const fetchInvoiceItem = async () => {
      try {
        const response = await axios.get(`/invoice/fetch/${invoiceId}`, {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        });
        setInvoiceData(response.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchInvoiceItem();
  }, []);

  const client = invoiceData?.invoice;
  const itemList = invoiceData?.invoiceItem;
  const customer = invoiceData?.customer;
  /**
   * Code For Go Reverce Page
   */

  const handleGoBack = () => {
    window.history.back();
  };

  /**
   * End
   */
  var targetRef = useRef(null);

  const handlePrint = () => {
    const targetElement = targetRef.current;

    if (targetElement) {
      window.print();
    }
  };
  return (
    <div id="print-content">
      {/* Header Wrapper Start */}
      <div className="container" id="section-to-print" ref={targetRef}>
        <div
          className="loan-application-main-wrapper"
          style={{
            width: "100%",
            margin: "0 auto",
            border: "1px solid #111",
            backgroundColor: "white",
            padding: "0px 11px",
          }}
        >
          <div className>
            <div className="row">
              <div
                className="col-lg-12 col-md-12 col-12 mar pad"
                style={{ padding: 0, margin: 0 }}
              >
                <div
                  className="invoice-title"
                  style={{
                    width: "100%",
                    float: "left",
                    textAlign: "center",
                    borderBottom: "1px solid #111",
                    paddingBottom: "2px",
                  }}
                >
                  <span
                    style={{
                      display: "inline-block",
                      textTransform: "uppercase",
                      fontWeight: 800,
                      color: "#111",
                      fontSize: "14px",
                    }}
                  >
                    <h1> Care Enterprises</h1>
                    <hr
                      style={{
                        width: "100%",
                        borderTop: "3px solid #000",
                        marginTop: "-9px",
                      }}
                    />
                  </span>
                  <h2
                    className="title-heading"
                    style={{
                      fontFamily: '"Roboto", sans-serif',
                      lineHeight: "1.1",
                      color: "#222222",
                      margin: 0,
                      padding: 0,
                      textAlign: "center",
                      fontWeight: 600,
                      textTransform: "uppercase",
                      fontSize: "15px",
                    }}
                  >
                    {auth?.user?.email}
                  </h2>
                  <p
                    style={{
                      margin: 0,
                      color: "#111",
                      fontWeight: 800,
                      lineHeight: "15px",
                      fontSize: "15px",
                    }}
                  >
                    Your Necessity is Our Responsibility
                  </p>
                  <p
                    style={{
                      margin: 0,
                      color: "#111",
                      fontWeight: 800,
                      fontSize: "15px",
                    }}
                  >
                    <b
                      style={{
                        // backgroundColor: "Blue",
                        lineHeight: "30px",
                        height: "100%",
                      }}
                    >
                      33, Lal Bahadur Shashtri Marg, Panchampura, Ujjain (M.P.)
                      Call 9109206324
                    </b>
                  </p>
                  {/* <p
                    style={{
                      margin: 0,
                      color: "#111",
                      fontWeight: 800,
                      lineHeight: "15px",
                      fontSize: "15px",
                    }}
                  >
                    Mob.: 9109206324
                  </p> */}
                  <p
                    style={{
                      margin: 0,
                      color: "#111",
                      fontWeight: 800,
                      lineHeight: "15px",
                      fontSize: "15px",
                    }}
                  >
                    GSTIN : 23AUWPM2491B1ZJ
                  </p>
                </div>
                {/*  */}
                <div
                  className="di-invoice-main-wrapper"
                  style={{
                    width: "100%",
                    float: "left",
                    borderBottom: "1px solid #111",
                  }}
                >
                  <div className="row">
                    <div
                      className="col-lg-6 col-md-6 col-6 mar pad"
                      style={{ padding: 0, margin: 0 }}
                    >
                      <div
                        className="invoice-left-side first-invoce-tb"
                        style={{
                          padding: "10px",
                          width: "100%",
                          float: "left",
                          position: "relative",
                        }}
                      >
                        <ul
                          style={{
                            listStyle: "none",
                            margin: 0,
                            padding: "2px 22px",
                          }}
                        >
                          <li
                            style={{
                              padding: "10px",
                              width: "100%",
                              color: "#111",
                              fontWeight: 600,
                              fontSize: "15px",
                              lineHeight: "18px",
                            }}
                          >
                            <b>Invoice No :</b>
                            <span
                              style={{
                                display: "inline-block",
                                paddingLeft: "8px",
                              }}
                            >
                              {data?.invoiceId}
                            </span>
                          </li>
                          <li
                            style={{
                              padding: "10px",
                              width: "100%",
                              color: "#111",
                              fontWeight: 600,
                              fontSize: "15px",
                              lineHeight: "18px",
                            }}
                          >
                            <b> Invoice Date :</b>
                            <span
                              style={{
                                display: "inline-block",
                                paddingLeft: "8px",
                              }}
                            >
                              {data?.saleDate}
                            </span>
                          </li>
                          <li
                            style={{
                              padding: "10px",
                              width: "100%",
                              color: "#111",
                              fontWeight: 600,
                              fontSize: "15px",
                              lineHeight: "18px",
                            }}
                          >
                            <b>Invoice Type:</b>
                            <span
                              style={{
                                display: "inline-block",
                                paddingLeft: "8px",
                              }}
                            >
                              Credit
                            </span>
                          </li>
                          <li
                            style={{
                              padding: "10px",
                              width: "100%",
                              color: "#111",
                              fontWeight: 600,
                              fontSize: "15px",
                              lineHeight: "18px",
                            }}
                          >
                            <b> Reserve Charge :</b>
                            <span
                              style={{
                                display: "inline-block",
                                paddingLeft: "8px",
                              }}
                            >
                              No
                            </span>
                          </li>
                          <li
                            style={{
                              padding: "10px",
                              width: "100%",
                              color: "#111",
                              fontWeight: 600,
                              fontSize: "15px",
                              lineHeight: "18px",
                            }}
                          >
                            <b>State :</b>
                            <span
                              style={{
                                display: "inline-block",
                                paddingLeft: "8px",
                              }}
                            >
                              {auth?.user?.state || "Madhya Pradesh"}
                            </span>
                          </li>
                          <li
                            style={{
                              padding: "10px",
                              width: "100%",
                              color: "#111",
                              fontWeight: 600,
                              fontSize: "15px",
                              lineHeight: "18px",
                            }}
                          >
                            <b>State Code :</b>
                            <span
                              style={{
                                display: "inline-block",
                                paddingLeft: "8px",
                              }}
                            >
                              {auth?.user?.state1 || "23"}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* <div
                      className="col-lg-6 col-md-6 col-6 mar pad"
                      style={{ padding: 0, margin: 0 }}
                    >
                      <div
                        className="invoice-left-side aftr-remove first-invoce-tb"
                        style={{
                          width: "100%",
                          float: "left",
                          position: "relative",
                        }}
                      >
                        <ul
                          style={{
                            listStyle: "none",
                            margin: 0,
                            padding: "2px 22px",
                          }}
                        >
                          <li
                            style={{
                              width: "100%",
                              color: "#111",
                              fontWeight: 600,
                              fontSize: "15px",
                              lineHeight: "18px",
                            }}
                          >
                            Transport Name :
                            <span
                              style={{
                                display: "inline-block",
                                paddingLeft: "8px",
                              }}
                            >
                              {data?.transportName}
                            </span>
                          </li>
                          <li
                            style={{
                              width: "100%",
                              color: "#111",
                              fontWeight: 600,
                              fontSize: "15px",
                              lineHeight: "18px",
                            }}
                          >
                            Veh No :
                            <span
                              style={{
                                display: "inline-block",
                                paddingLeft: "8px",
                              }}
                            >
                              {data?.vehNumber}
                            </span>
                          </li>
                          <li
                            style={{
                              width: "100%",
                              color: "#111",
                              fontWeight: 600,
                              fontSize: "15px",
                              lineHeight: "18px",
                            }}
                          >
                            LR No :
                            <span
                              style={{
                                display: "inline-block",
                                paddingLeft: "8px",
                              }}
                            >
                              {data?.lrNumber}
                            </span>
                          </li>
                          <li
                            style={{
                              width: "100%",
                              color: "#111",
                              fontWeight: 600,
                              fontSize: "15px",
                              lineHeight: "18px",
                            }}
                          >
                            E-Way Bill No :
                            <span
                              style={{
                                display: "inline-block",
                                paddingLeft: "8px",
                              }}
                            >
                              {data?.ewayBillNumber}
                            </span>
                          </li>
                          <li
                            style={{
                              width: "100%",
                              color: "#111",
                              fontWeight: 600,
                              fontSize: "15px",
                              lineHeight: "18px",
                            }}
                          >
                            E-Way Bill Date :
                            <span
                              style={{
                                display: "inline-block",
                                paddingLeft: "8px",
                              }}
                            >
                              {data?.ewayBillDate}
                            </span>
                          </li>
                          <li
                            style={{
                              width: "100%",
                              color: "#111",
                              fontWeight: 600,
                              fontSize: "15px",
                              lineHeight: "18px",
                            }}
                          >
                            Place of Supply :
                            <span
                              style={{
                                display: "inline-block",
                                paddingLeft: "8px",
                              }}
                            >
                              23 Madhya Pradesh
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div
                  className="di-invoice-main-wrapper"
                  style={{
                    width: "100%",
                    float: "left",
                    borderBottom: "1px solid #111",
                    fontSize: "14px",
                  }}
                >
                  <div className="row">
                    <div
                      className="col-lg-6 col-md-6 col-6 pad-right"
                      style={{ paddingRight: 0 }}
                    >
                      <div
                        className="invoice-left-side aftr-remove"
                        style={{
                          width: "100%",
                          float: "left",
                          position: "relative",
                        }}
                      >
                        <div
                          className="invoice-buyer-title"
                          style={{
                            borderBottom: "1px solid #111",
                            padding: "5px 0px",
                          }}
                        >
                          <p
                            style={{
                              margin: 0,
                              paddingLeft: "10px",
                              fontWeight: 600,
                              fontSize: "16px",
                              color: "#111",
                            }}
                          >
                            Buyer | Details of Receiver | Billed to
                          </p>
                        </div>
                        <ul
                          style={{
                            listStyle: "none",
                            margin: 0,
                            padding: "10px 10px",
                            width: "50%",
                            paddingRight: "0px",
                            position: "absolute",
                            height: "100%",
                            float: "left",
                            // borderLeft: "1px solid #111",
                            fontWeight: 600,
                          }}
                        >
                          <li
                            style={{
                              width: "100%",
                              color: "#111",
                              fontSize: "15px",
                              lineHeight: "18px",
                            }}
                          >
                            <b> Name : </b> {customer?.customerName}
                          </li>
                          <li
                            style={{
                              width: "100%",
                              color: "#111",
                              fontSize: "15px",
                              lineHeight: "18px",
                            }}
                          >
                            Address : {customer?.address}
                          </li>
                          <li
                            style={{
                              width: "100%",
                              color: "#111",
                              fontSize: "15px",
                              lineHeight: "18px",
                            }}
                          >
                            Mobile : {customer?.mobile || "900000000"}
                          </li>
                          <li
                            style={{
                              width: "100%",
                              color: "#111",
                              fontSize: "15px",
                              lineHeight: "18px",
                            }}
                          >
                            GSTIN : {customer?.gstin || ""}
                          </li>
                          <li
                            style={{
                              width: "100%",
                              color: "#111",
                              fontSize: "15px",
                              lineHeight: "18px",
                            }}
                          >
                            State :{"  "}Madhya Pradesh
                          </li>
                          <li
                            style={{
                              width: "100%",
                              color: "#111",
                              fontSize: "15px",
                              lineHeight: "18px",
                            }}
                          >
                            State Code : {"  "}23
                          </li>
                        </ul>
                        {/* <ul
                          style={{
                            listStyle: "none",
                            margin: 0,
                            padding: "10px 10px",
                            width: "50%",
                            paddingRight: "0px",
                            minHeight: "160px",
                            float: "right",
                            borderRight: "1px solid",
                            fontWeight: 600,
                          }}
                        >
                          <li
                            style={{
                              width: "100%",
                              color: "#111",
                              fontSize: "15px",
                              lineHeight: "18px",
                            }}
                          >
                            <b> Name :{data?.loadingName} </b>
                          </li>
                          <li
                            style={{
                              width: "100%",
                              color: "#111",
                              fontSize: "15px",
                              lineHeight: "18px",
                            }}
                          >
                            Address : {data?.loadingAddress}{" "}
                            {data?.loadingPincode}
                          </li>
                          <li
                            style={{
                              width: "100%",
                              color: "#111",
                              fontSize: "15px",
                              lineHeight: "18px",
                            }}
                          >
                            GSTIN : {data?.loadingGSTIN}
                          </li>
                          <li
                            style={{
                              width: "100%",
                              color: "#111",
                              fontSize: "15px",
                              lineHeight: "18px",
                            }}
                          >
                            State :{data?.loadingState}, State Code :{" "}
                          </li>
                        </ul> */}
                      </div>
                    </div>
                    <div
                      className="col-lg-6 col-md-6 col-6 pad-left"
                      style={{ paddingLeft: 0, borderLeft: "1px solid #111" }}
                    >
                      <div
                        className="invoice-left-side aftr-remove"
                        style={{
                          width: "100%",
                          float: "left",
                          position: "relative",
                        }}
                      >
                        <div
                          className="invoice-buyer-title"
                          style={{
                            borderBottom: "1px solid #111",
                            padding: "5px 0px",
                          }}
                        >
                          <p
                            style={{
                              margin: 0,
                              paddingLeft: "10px",
                              fontWeight: 600,
                              fontSize: "16px",
                              color: "#111",
                            }}
                          >
                            Details of Consignee | Shipped to
                          </p>
                        </div>
                        <ul
                          style={{
                            listStyle: "none",
                            margin: 0,
                            padding: "10px 10px",
                            width: "50%",
                            paddingRight: "0px",
                            position: "absolute",
                            height: "100%",
                            float: "left",
                            // borderRight: "1px solid",
                            fontWeight: 600,
                          }}
                        >
                          <li
                            style={{
                              width: "100%",
                              color: "#111",
                              fontSize: "15px",
                              lineHeight: "18px",
                            }}
                          >
                            <b> Name : </b>ABCD
                          </li>
                          <li
                            style={{
                              width: "100%",
                              color: "#111",
                              fontSize: "15px",
                              lineHeight: "18px",
                            }}
                          >
                            Address : ABCDEFGHIJ
                          </li>
                          <li
                            style={{
                              width: "100%",
                              color: "#111",
                              fontSize: "15px",
                              lineHeight: "18px",
                            }}
                          >
                            GSTIN : ABCDEFGHIJKLMNOPQRSTU
                          </li>
                          <li
                            style={{
                              width: "100%",
                              color: "#111",
                              fontSize: "15px",
                              lineHeight: "18px",
                            }}
                          >
                            State :Madhya Pradesh
                          </li>
                          <li
                            style={{
                              width: "100%",
                              color: "#111",
                              fontSize: "15px",
                              lineHeight: "18px",
                            }}
                          >
                            State Code : 23
                          </li>
                        </ul>
                        <ul
                          style={{
                            listStyle: "none",
                            margin: 0,
                            padding: "10px 10px",
                            width: "50%",
                            paddingRight: "0px",
                            minHeight: "160px",
                            float: "right",
                            borderRight: "1px solid",
                            fontWeight: 600,
                          }}
                        >
                          {/* <li
                            style={{
                              width: "100%",
                              color: "#111",
                              fontSize: "15px",
                              lineHeight: "18px",
                            }}
                          >
                            <b> Name : {data?.shippingName} </b>
                          </li>
                          <li
                            style={{
                              width: "100%",
                              color: "#111",
                              fontSize: "15px",
                              lineHeight: "18px",
                            }}
                          >
                            Address :{data?.shippingAddress}
                            {" , "}
                            {data?.shippingPincode}
                          </li>
                          <li
                            style={{
                              width: "100%",
                              color: "#111",
                              fontSize: "15px",
                              lineHeight: "18px",
                            }}
                          >
                            GSTIN :{data?.shippingGSTIN}
                          </li>
                          <li
                            style={{
                              width: "100%",
                              color: "#111",
                              fontSize: "15px",
                              lineHeight: "18px",
                            }}
                          >
                            State : {data?.shippingState}, State Code :{" "}
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/*  */}
                <div
                  className="table-invoice-details"
                  style={{ width: "100%", float: "left" }}
                >
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                      {/*<div class="table table-responsive"> */}
                      <div
                        className="table"
                        style={{
                          marginBottom: "0px",
                          position: "relative",
                          background: "white",
                        }}
                      >
                        <table
                          className="table table-bordered m-0"
                          style={{
                            marginBottom: "0px",
                            position: "relative",
                            background: "white",
                            width: "100%",
                          }}
                          width="100%"
                        >
                          <thead>
                            <tr style={{ lineHeight: "19px" }}>
                              <th
                                className="w-12"
                                style={{
                                  border: "solid 1px #111111",
                                  background: "#ccc",
                                  WebkitPrintColorAdjust: "exact",
                                  width: "211px",
                                  textAlign: "center",
                                  lineHeight: "0.9",
                                  fontSize: "11px",
                                  padding: "3px 2px",
                                }}
                                align="center"
                              >
                                Sr No.
                              </th>
                              <th
                                className="w-22"
                                style={{
                                  border: "solid 1px #111111",
                                  background: "#ccc",
                                  WebkitPrintColorAdjust: "exact",
                                  width: "211px",
                                  textAlign: "center",
                                  lineHeight: "0.9",
                                  fontSize: "11px",
                                  padding: "3px 2px",
                                }}
                                align="center"
                              >
                                Description of Goods
                              </th>
                              <th
                                style={{
                                  border: "solid 1px #111111",
                                  background: "#ccc",
                                  WebkitPrintColorAdjust: "exact",
                                  width: "211px",
                                  textAlign: "center",
                                  lineHeight: "0.9",
                                  fontSize: "11px",
                                  padding: "3px 2px",
                                }}
                                align="center"
                              >
                                HSN/SAC
                              </th>
                              <th
                                className="w-32"
                                style={{
                                  border: "solid 1px #111111",
                                  background: "#ccc",
                                  WebkitPrintColorAdjust: "exact",
                                  width: "211px",
                                  textAlign: "center",
                                  lineHeight: "0.9",
                                  fontSize: "11px",
                                  padding: "3px 2px",
                                }}
                                align="center"
                              >
                                Quantity UQC
                              </th>
                              <th
                                className="w-42"
                                style={{
                                  border: "solid 1px #111111",
                                  background: "#ccc",
                                  WebkitPrintColorAdjust: "exact",
                                  width: "211px",
                                  textAlign: "center",
                                  lineHeight: "0.9",
                                  fontSize: "11px",
                                  padding: "3px 2px",
                                }}
                                align="center"
                              >
                                Rate
                              </th>
                              <th
                                className="w-42"
                                style={{
                                  border: "solid 1px #111111",
                                  background: "#ccc",
                                  WebkitPrintColorAdjust: "exact",
                                  width: "211px",
                                  textAlign: "center",
                                  lineHeight: "0.9",
                                  fontSize: "11px",
                                  padding: "3px 2px",
                                }}
                                align="center"
                              >
                                Amount
                              </th>
                              <th
                                className="w-22"
                                style={{
                                  border: "solid 1px #111111",
                                  background: "#ccc",
                                  WebkitPrintColorAdjust: "exact",
                                  width: "211px",
                                  textAlign: "center",
                                  lineHeight: "0.9",
                                  fontSize: "11px",
                                  padding: "3px 2px",
                                }}
                                align="center"
                              >
                                Discount
                              </th>
                              {/* <th
                                style={{
                                  border: "solid 1px #111111",
                                  background: "#ccc",
                                  WebkitPrintColorAdjust: "exact",
                                  textAlign: "center",
                                  lineHeight: "0.9",
                                  fontSize: "11px",
                                  width: "250px",
                                  padding: "3px 2px",
                                }}
                                align="center"
                              >
                                GST Amount / Rate
                              </th> */}
                              {/* <th
                                style={{
                                  border: "solid 1px #111111",
                                  background: "#ccc",
                                  WebkitPrintColorAdjust: "exact",
                                  textAlign: "center",
                                  lineHeight: "0.9",
                                  fontSize: "11px",
                                  width: "250px",
                                  padding: "3px 2px",
                                }}
                                align="center"
                              >
                                SGST Amount / Rate
                              </th> */}
                              <th
                                className="w-42"
                                style={{
                                  border: "solid 1px #111111",
                                  background: "#ccc",
                                  WebkitPrintColorAdjust: "exact",
                                  width: "211px",
                                  textAlign: "center",
                                  lineHeight: "0.9",
                                  fontSize: "11px",
                                  padding: "3px 2px",
                                }}
                                align="center"
                              >
                                Value Of Supply
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {itemList?.map((item, index) => (
                              <tr key={index} style={{ lineHeight: "19px" }}>
                                <td
                                  style={{
                                    border: "solid 1px #111111",
                                    fontWeight: 600,
                                    fontSize: "15px",
                                    lineHeight: "22px",
                                    padding: "0px 5px",
                                  }}
                                >
                                  {index + 1}
                                </td>
                                <td
                                  style={{
                                    border: "solid 1px #111111",
                                    fontWeight: 600,
                                    fontSize: "15px",
                                    lineHeight: "22px",
                                    padding: "0px 5px",
                                  }}
                                >
                                  {item?.productName}
                                </td>
                                <td
                                  style={{
                                    border: "solid 1px #111111",
                                    fontWeight: 600,
                                    fontSize: "15px",
                                    lineHeight: "22px",
                                    padding: "0px 5px",
                                  }}
                                >
                                  {item?.hsnCode || "ABC"}
                                </td>
                                <td
                                  style={{
                                    border: "solid 1px #111111",
                                    fontWeight: 600,
                                    fontSize: "15px",
                                    lineHeight: "22px",
                                    padding: "0px 5px",
                                  }}
                                >
                                  {item?.quantity} {item.unit}
                                </td>
                                <td
                                  style={{
                                    border: "solid 1px #111111",
                                    fontWeight: 600,
                                    fontSize: "15px",
                                    lineHeight: "22px",
                                    padding: "0px 5px",
                                  }}
                                >
                                  {item?.price}
                                </td>
                                <td
                                  style={{
                                    border: "solid 1px #111111",
                                    fontWeight: 600,
                                    fontSize: "15px",
                                    lineHeight: "22px",
                                    padding: "0px 5px",
                                  }}
                                >
                                  {item?.itemTotal}
                                </td>
                                <td
                                  style={{
                                    border: "solid 1px #111111",
                                    fontWeight: 600,
                                    fontSize: "15px",
                                    lineHeight: "22px",
                                    padding: "0px 5px",
                                  }}
                                >
                                  {item?.discount || "----"}
                                </td>
                                {/* <td
                                  style={{
                                    border: "solid 1px #111111",
                                    fontWeight: 600,
                                    fontSize: "15px",
                                    lineHeight: "22px",
                                    padding: "0px 5px",
                                  }}
                                >
                                  {Math.round(
                                    item?.grandTotal - item?.itemTotal
                                  ) || "0"}
                                  {"  / "}
                                  {item?.gstRate || "0"}
                                  {" % "}
                                </td> */}
                                {/* <td
                                  style={{
                                    border: "solid 1px #111111",
                                    fontWeight: 600,
                                    fontSize: "15px",
                                    lineHeight: "22px",
                                    padding: "0px 5px",
                                  }}
                                >
                                  {item?.totalTaxAmount}
                                  {"  / "}
                                  {item?.sgst1}
                                  {" % "}
                                </td> */}
                                <td
                                  style={{
                                    border: "solid 1px #111111",
                                    fontWeight: 600,
                                    fontSize: "15px",
                                    lineHeight: "22px",
                                    padding: "0px 5px",
                                  }}
                                >
                                  {item?.grandTotal}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <div className="table_box">
                          <div
                            className="tableBox row m-0"
                            style={{
                              border: "1px solid #000",
                              borderTop: 0,
                              lineHeight: "15px",
                              fontSize: "15px",
                            }}
                          >
                            <div
                              className="terms-text col-6"
                              style={{
                                lineHeight: "19px",
                                padding: "4px 10px",
                              }}
                            >
                              <ul
                                style={{
                                  listStyle: "none",
                                  margin: 0,
                                  padding: 0,
                                }}
                              >
                                <li
                                  style={{
                                    lineHeight: "18px",
                                    fontWeight: 600,
                                  }}
                                >
                                  Terms &amp; Conditions
                                </li>
                                <li
                                  style={{
                                    lineHeight: "18px",
                                    fontWeight: 600,
                                  }}
                                >
                                  1. Good received in good condition
                                </li>
                                <li
                                  style={{
                                    lineHeight: "18px",
                                    fontWeight: 600,
                                  }}
                                >
                                  2. Good one sold will not be taken back
                                </li>
                                <li
                                  style={{
                                    lineHeight: "18px",
                                    fontWeight: 600,
                                  }}
                                >
                                  3. We are not responsible for any loss or
                                  damage in transit.
                                </li>
                                <li
                                  style={{
                                    lineHeight: "18px",
                                    fontWeight: 600,
                                  }}
                                >
                                  4. Good are dispatched entirely at the risk of
                                  customer
                                </li>
                                <li
                                  style={{
                                    lineHeight: "18px",
                                    fontWeight: 600,
                                  }}
                                >
                                  5. Interest @ 24% will be charge if invoice
                                  amount not paid within 15 days
                                </li>
                                <li
                                  style={{
                                    lineHeight: "18px",
                                    fontWeight: 600,
                                  }}
                                >
                                  6. All disputes are subject to indore ( M.P.)
                                  Jurisdiction E &amp; OE
                                </li>
                              </ul>
                            </div>
                            <div
                              className="pl-left col-6"
                              style={{
                                lineHeight: "19px",
                                borderLeft: "1px solid #000",
                                padding: 0,
                                paddingLeft: "40px",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                position: "relative",
                              }}
                            >
                              <div
                                className="terms-text"
                                style={{ padding: "4px 10px" }}
                              >
                                <ul
                                  style={{
                                    listStyle: "none",
                                    margin: 0,
                                    padding: 0,
                                  }}
                                >
                                  <li
                                    style={{
                                      lineHeight: "18px",
                                      fontWeight: 600,
                                    }}
                                  >
                                    <b>Total Amount Before Tax</b>
                                  </li>
                                  <li
                                    style={{
                                      lineHeight: "18px",
                                      fontWeight: 600,
                                    }}
                                  >
                                    <b>Total Discount</b>
                                  </li>
                                  <li
                                    style={{
                                      lineHeight: "18px",
                                      fontWeight: 600,
                                    }}
                                  >
                                    <b>Add : GST</b>
                                  </li>
                                  <li
                                    style={{
                                      lineHeight: "18px",
                                      fontWeight: 600,
                                    }}
                                  >
                                    <b>Add : SGST</b>
                                  </li>
                                  <li
                                    style={{
                                      lineHeight: "18px",
                                      fontWeight: 600,
                                    }}
                                  >
                                    <b>Frieght</b>
                                  </li>
                                  <li
                                    style={{
                                      lineHeight: "18px",
                                      fontWeight: 600,
                                    }}
                                  >
                                    <b>RF off &amp; Ad (+ / -)</b>
                                  </li>
                                </ul>
                              </div>
                              <div
                                className="terms-text pull-right"
                                style={{ padding: "4px 10px" }}
                              >
                                <ul
                                  style={{
                                    listStyle: "none",
                                    margin: 0,
                                    padding: 0,
                                  }}
                                >
                                  <li
                                    style={{
                                      lineHeight: "18px",
                                      fontWeight: 600,
                                    }}
                                  >
                                    <b>{data?.total_before_tax}</b>
                                  </li>
                                  <li
                                    style={{
                                      lineHeight: "18px",
                                      fontWeight: 600,
                                    }}
                                  >
                                    <b>{data?.discount}</b>
                                  </li>
                                  <li
                                    style={{
                                      lineHeight: "18px",
                                      fontWeight: 600,
                                    }}
                                  >
                                    <b>{data?.gstRate}</b>
                                  </li>
                                  {/* <li
                                    style={{
                                      lineHeight: "18px",
                                      fontWeight: 600,
                                    }}
                                  >
                                    <b>{data?.sgst}</b>
                                  </li> */}
                                  <li
                                    style={{
                                      lineHeight: "18px",
                                      fontWeight: 600,
                                    }}
                                  >
                                    <b>{data?.other}</b>
                                  </li>
                                  <li
                                    style={{
                                      lineHeight: "18px",
                                      fontWeight: 600,
                                    }}
                                  >
                                    <b>-</b>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tableBox row m-0"
                            style={{
                              border: "1px solid #000",
                              borderTop: 0,
                              lineHeight: "15px",
                              fontSize: "15px",
                            }}
                          >
                            <div
                              className="terms-text col-6"
                              style={{
                                lineHeight: "19px",
                                padding: "4px 10px",
                              }}
                            >
                              <ul
                                style={{
                                  listStyle: "none",
                                  margin: 0,
                                  padding: 0,
                                }}
                              >
                                <li
                                  style={{
                                    lineHeight: "18px",
                                    fontWeight: 600,
                                  }}
                                >
                                  <b>Declaration :</b>
                                </li>
                                <li
                                  style={{
                                    lineHeight: "18px",
                                    fontWeight: 600,
                                  }}
                                >
                                  we declare that this invoice show the actual
                                  price on the goods described and that all
                                  particulars are true and correct.
                                </li>
                              </ul>
                            </div>
                            <div
                              className="pl-left col-6"
                              style={{
                                lineHeight: "19px",
                                borderLeft: "1px solid #000",
                                padding: 0,
                                paddingLeft: "40px",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                position: "relative",
                              }}
                            >
                              <h4
                                className="bank-title pl-left"
                                style={{
                                  fontFamily: '"Roboto", sans-serif',
                                  lineHeight: "1.1",
                                  color: "#222222",
                                  margin: 0,
                                  padding: 0,
                                  paddingLeft: "40px",
                                  fontSize: "18px",
                                  fontWeight: 700,
                                  width: "100%",
                                }}
                              >
                                Grand Total
                              </h4>
                              <div
                                className="terms-text"
                                style={{ padding: "4px 10px", width: "145px" }}
                              >
                                <h4
                                  className="bank-title right-side"
                                  style={{
                                    fontFamily: '"Roboto", sans-serif',
                                    lineHeight: "1.1",
                                    color: "#222222",
                                    margin: 0,
                                    padding: 0,
                                    fontSize: "18px",
                                    fontWeight: 700,
                                    width: "100%",
                                    textAlign: "right",
                                  }}
                                >
                                  {Math.round(data?.grandTotal)} /-
                                </h4>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tableBox row m-0"
                            style={{
                              border: "1px solid #000",
                              borderTop: 0,
                              lineHeight: "15px",
                              fontSize: "15px",
                            }}
                          >
                            <div
                              className="col-4"
                              style={{ lineHeight: "19px", padding: "0px" }}
                            >
                              <div
                                className="terms-text"
                                style={{ padding: "4px 10px" }}
                              >
                                <h4
                                  className="bank-title"
                                  style={{
                                    fontFamily: '"Roboto", sans-serif',
                                    lineHeight: "1.1",
                                    color: "#222222",
                                    margin: 0,
                                    padding: 0,
                                    textAlign: "center",
                                    fontWeight: 700,
                                    width: "100%",
                                    fontSize: "14px",
                                  }}
                                >
                                  :: Bank Details ::
                                </h4>
                                <div
                                  className="bank-detals-box"
                                  style={{ display: "flex", marginTop: "2px" }}
                                >
                                  <div
                                    className="bank-label"
                                    style={{ width: "115px" }}
                                  >
                                    <p
                                      style={{
                                        margin: 0,
                                        lineHeight: "15px",
                                        fontWeight: 600,
                                        color: "#111",
                                        fontSize: "15px",
                                      }}
                                    >
                                      Bank Name
                                    </p>
                                    <p
                                      style={{
                                        margin: 0,
                                        lineHeight: "15px",
                                        fontWeight: 600,
                                        color: "#111",
                                        fontSize: "15px",
                                      }}
                                    >
                                      Branch Name
                                    </p>
                                    <p
                                      style={{
                                        margin: 0,
                                        lineHeight: "15px",
                                        fontWeight: 600,
                                        color: "#111",
                                        fontSize: "15px",
                                      }}
                                    >
                                      A/C No.
                                    </p>
                                    <p
                                      style={{
                                        margin: 0,
                                        lineHeight: "15px",
                                        fontWeight: 600,
                                        color: "#111",
                                        fontSize: "15px",
                                      }}
                                    >
                                      IFSC Code
                                    </p>
                                  </div>
                                  <div className="bank-fill">
                                    <p
                                      style={{
                                        margin: 0,
                                        lineHeight: "15px",
                                        fontWeight: 600,
                                        color: "#111",
                                        fontSize: "15px",
                                      }}
                                    >
                                      :Bank of India
                                    </p>
                                    <p
                                      style={{
                                        margin: 0,
                                        lineHeight: "15px",
                                        fontWeight: 600,
                                        color: "#111",
                                        fontSize: "15px",
                                      }}
                                    >
                                      :Indore
                                    </p>
                                    <p
                                      style={{
                                        margin: 0,
                                        lineHeight: "15px",
                                        fontWeight: 600,
                                        color: "#111",
                                        fontSize: "15px",
                                      }}
                                    >
                                      :
                                    </p>
                                    <p
                                      style={{
                                        margin: 0,
                                        lineHeight: "15px",
                                        fontWeight: 600,
                                        color: "#111",
                                        fontSize: "15px",
                                      }}
                                    >
                                      :
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="pl-left col-6 col-8"
                              style={{
                                lineHeight: "19px",
                                borderLeft: "1px solid #000",
                                padding: 0,
                                paddingLeft: "40px",
                                display: "flex",
                                alignItems: "center",
                                position: "static",
                                justifyContent: "center",
                              }}
                            >
                              <div
                                className="terms-text demo"
                                style={{
                                  textAlign: "center",
                                  padding: "4px 10px",
                                }}
                              >
                                <ul
                                  style={{
                                    listStyle: "none",
                                    margin: 0,
                                    padding: 0,
                                  }}
                                >
                                  <li
                                    style={{
                                      lineHeight: "18px",
                                      fontWeight: 600,
                                    }}
                                  >
                                    <b>Care Enterprises</b>
                                  </li>
                                  <li
                                    style={{
                                      lineHeight: "18px",
                                      marginTop: "40px",
                                      fontWeight: 600,
                                    }}
                                  >
                                    <b>Authorised Signature</b>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*  */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <center>
        <button
          className="btn-convert"
          style={{
            outline: "none",
            backgroundColor: "#f44336",
            border: "none",
            color: "white",
            padding: "15px 32px",
            textAlign: "center",
            textDecoration: "none",
            display: "inline-block",
            fontSize: "16px",
            margin: "4px 2px",
            cursor: "pointer",
          }}
          onClick={handlePrint}
        >
          Print Invoice
        </button>
      </center>
      <br />
      <center>
        <button
          className="btn-convert"
          style={{
            outline: "none",
            backgroundColor: "#f44336",
            border: "none",
            color: "white",
            padding: "15px 32px",
            textAlign: "center",
            textDecoration: "none",
            display: "inline-block",
            fontSize: "16px",
            margin: "4px 2px",
            cursor: "pointer",
          }}
          onClick={handleGoBack}
        >
          Go Back
        </button>
      </center>
      <br />
      <StatusToast />
    </div>
  );
};

export default ViewInvoice;
